import React, { Component } from 'react';
import gif404 from './404.gif';
import Helmet from "react-helmet";
import { I18n } from 'react-redux-i18n';

export default class NotFound extends Component {
  componentDidMount() {
    }
  render() {
    return (
      <div style={{margin: '0 auto', width: '400px'}}>
        <Helmet
					title={I18n.t('project.name')}
				/>
        <h1>404!</h1>
        <p>Oops, no such page exists.</p>
        <img src={gif404} alt='404 gif'/>
      </div>
    );
  }
}
NotFound.contextTypes={
}