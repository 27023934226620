import {en} from './default/en'

function getLanguage() {
    console.log("process.env.REACT_APP_PROJECT",process.env.REACT_APP_PROJECT)
    switch (process.env.REACT_APP_PROJECT) {
        default: 
            return {
                en
            }
    }
}

export const translations = getLanguage();