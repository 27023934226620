import { callApi } from "../utils/apiUtils";
import {
  ugcVideoGetListApi,
  ugcVideoGetDetailApi,
  ugcVideoUpdateApi,
} from "../constants/ApiConfigs";

export function ugcVideoGetList(options) {
  let config = {
    method: "post",
    body: JSON.stringify(options),
  };
  return callApi(ugcVideoGetListApi, config);
}

export function ugcVideoGetDetail(options) {
  let config = {
    method: "post",
    body: JSON.stringify(options),
  };
  return callApi(ugcVideoGetDetailApi, config, null, null, null, true);
}

export function ugcVideoUpdate(options) {
  let config = {
    method: "post",
    body: JSON.stringify(options),
  };
  return callApi(ugcVideoUpdateApi, config, null, null, null, true);
}
