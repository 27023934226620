import {callApi,callApiBase,loadUserProfile, UrlBuilder} from '../utils/apiUtils'
import { videoCreateApi,
        videoDeleteApi,
        videoGetDetailApi,
        videoGetListApi,
        videoUpdateApi} from '../constants/ApiConfigs'

export function videoGetDetail(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        videoGetDetailApi,
        config,
        null,
        null,
        null,
        true
    )
}

export function videoGetList(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        videoGetListApi,
        config,
        null,
        null,
        null,
        true
    )
}

export function videoCreate(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        videoCreateApi,
        config,
        null,
        null,
        null,
        true
    )
}
export function videoUpdate(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        videoUpdateApi,
        config,
        null,
        null,
        null,
        true
    )
}
export function videoDelete(options){
    let config = {
        method: 'DELETE',
        body:JSON.stringify(options)
    }
    return callApi(
        videoDeleteApi,
        config,
        null,
        null,
        null,
        true
    )
}