export const serverBase = process.env.REACT_APP_API_SERVER;
export const userLoginApi = serverBase + "user/login";
export const userLogoutApi = serverBase + "user/logout";
export const userChangePasswordApi = serverBase + "user/change_password";
export const userForgotPasswordApi = serverBase + "user/forgot_password";
export const userResetPasswordApi = serverBase + "user/reset_password";
export const userEditProfileApi = serverBase + "user/update";

//category APis
export const categoryCreateApi = serverBase + "category/create";
export const categoryGeAllApi = serverBase + "category/get";
export const categoryGetDetailApi = serverBase + "category/detail";
export const categoryUpdateApi = serverBase + "category/update";
export const categoryDeleteApi = serverBase + "category/delete";

//Video APis
export const videoCreateApi = serverBase + "video/create";
export const videoGetListApi = serverBase + "video/get";
export const videoGetDetailApi = serverBase + "video/detail";
export const videoUpdateApi = serverBase + "video/update";
export const videoDeleteApi = serverBase + "video/delete";

//user APis
export const userCreateApi = serverBase + "user/create";
export const userGetListApi = serverBase + "user/get";
export const userGetDetailApi = serverBase + "user/detail";
export const userUpdateApi = serverBase + "user/update_info";
export const userDeleteApi = serverBase + "user/delete";

//Payment Item
export const paymentItemCreateApi = serverBase + "payment_item/create";
export const paymentItemGetListApi = serverBase + "payment_item/get";
export const paymentItemGetDetailApi = serverBase + "payment_item/detail";
export const paymentItemUpdateApi = serverBase + "payment_item/update";
export const paymentItemDeleteApi = serverBase + "payment_item/delete";

//user package
export const packageCreateApi = serverBase + "package/create";
export const packageGetListApi = serverBase + "package/get";
export const packageGetDetailApi = serverBase + "package/detail";
export const packageUpdateApi = serverBase + "package/update";
export const packageDeleteApi = serverBase + "package/delete";

//ugcvideo
export const ugcVideoGetListApi = serverBase + "ugcvideo/get-all";
export const ugcVideoGetDetailApi = serverBase + "ugcvideo/detail";
export const ugcVideoUpdateApi = serverBase + "ugcvideo/update";

//ugccomment
export const ugcCommentCreateApi = serverBase + "ugc-comment/create";
export const ugcCommentsGetApi = (ugcVideoId) =>
  `${serverBase}ugc-comment/${ugcVideoId}/get`;
