import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import './table.css'
import {
    Pagination
} from 'react-bootstrap';
class TableHeader extends Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.data) {
            return true;
        }
        return false;
    }


    render() {
        if (!this.props.data) {
            return null;
        }
        let items = 1;
        let indents = [];
        let limites = [5, 10, 20, 50, 70, 100]
        if (this.props.limites) limites = this.props.limites
        items = Math.ceil(this.props.data.total / this.props.data.limit);
        for (let i = 0; i < Math.ceil(this.props.data.total / this.props.data.limit); i++) {
            indents.push(<option key={i} value={i}>{i+1}</option>);
        }
        let startItems = this.props.data.total === 0 ? 0 : (this.props.data.page * this.props.data.limit) + 1;
        let endItems = ((this.props.data.page + 1) * this.props.data.limit) >=
            this.props.data.total ? this.props.data.total : ((this.props.data.page + 1) * this.props.data.limit);
        return (<div className={this.props.isHeader ? 'clearfix cue-table-control header' : 'clearfix cue-table-control footer'}>
            <div className='left-controls'>
                <div className='control-group form-inline'>
                    <label><Translate value='table_header.Items_per_page' /></label>
                    <select className="form-control page-control" value={this.props.data.limit} onChange={this.props.handleNumItemsPerPageChange}>
                        {
                            limites.map((obj, index) => { return <option key={index} value={obj}>{obj}</option> })
                        }
                    </select>
                    <label><Translate value='table_header.Page' /></label>
                    <select className="form-control page-control" value={(this.props.data.page)} onChange={(e) => {
                        this.props.handlePaginationSelect(parseInt(e.target.value));
                    }}>
                        {indents}
                    </select>
                    <label>/{items}</label>
                    {this.props.handleSearchChange ?
                        <input type='text' className='form-control page-control' ref="searchInput"
                            value={this.props.value}
                            onKeyPress={this.props.handleSearchKeyPress}
                            onChange={(e) => {
                                this.props.handleSearchChange(e.target.value);
                            }}
                            placeholder={I18n.t('table_header.Search_here')} />
                        : null}
                </div>
            </div>
            <div className='right-controls clearfix'>
                <div className='control-group form-inline'>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis={false}
                        boundaryLinks={false}
                        items={items}
                        maxButtons={5}
                        activePage={(this.props.data.page + 1)}
                        onSelect={(page) => {
                            this.props.handlePaginationSelect(page);
                        }} />
                </div>
                <div className='control-group form-inline paging-lage'>
                    <label>{I18n.t('table_header.Shown_Active').format(startItems, endItems, this.props.data.total)}
                        {this.props.data.active != null ? I18n.t('table_header.Active').format(this.props.data.active) : null}
                        {this.props.data.active != null && this.props.name ? this.props.name : null}
                    </label>
                </div>
            </div>
        </div>
        )
    }
}
export default connect()(TableHeader);
