import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Translate, I18n } from 'react-redux-i18n';
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    ControlLabel,
    InputGroup,
    Button, Radio,
    Glyphicon,
    ButtonToolbar,
    DropdownButton,
    MenuItem,
    Modal,
    ListGroup,
    ListGroupItem,
    Checkbox,
    Tab,
    Tabs,
    Alert,
    Pagination
} from 'react-bootstrap';
import Datetime from 'react-datetime';
import * as paymentItemActions from '../../actions/paymentItemActions'
import * as loadingBarActions from './../../actions/loadingBarActions'
import { bindActionCreators } from 'redux';
import { Validator, ValidCase } from '../../components/validator'
import {Validation,textboxNumberHelper } from '../../utils/commonFunctions'
import TableHeader from '../../components/table/TableHeader';
import defaultBioAvatar from '../../constants/staticFiles/default_bio.jpg'
var moment = require('moment');
var timeoutID = null;
class PaymentItem extends Component {
    constructor() {
        super();
        this.state = {
            paymentItems: null,
            activePage: 1,
            numItemsPerPage: 20,
            detailItem: null,
            showDialog: false,
            dialogData: null,
            dialogChanged: false,
            showConfirm: false,
            sortType: -1,
            valid: {},
            isSubmitted: false,
            editable: false,
            filter:{}
        }
        this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
        this.handlePaginationChose = this.handlePaginationChose.bind(this);
        this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.updatePaymentItemsList = this.updatePaymentItemsList.bind(this);
        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.closeDialogForm = this.closeDialogForm.bind(this);
        this.saveDialogForm = this.saveDialogForm.bind(this);
        this.handleInpputChange = this.handleInpputChange.bind(this);
        this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
        this.ValidatorCallback = this.ValidatorCallback.bind(this)
        this.handleFilter = this.handleFilter.bind(this);
    }
    componentDidMount() {
        this.updatePaymentItemsList();
    }

    updatePaymentItemsList(activePage,query={}) {
        activePage = activePage || 1;
        this.props.paymentItemActions.paymentItemGetList().then(data => {
            if (data.ok && data.res) {
                this.setState({ paymentItems: data.res })
            }
        });
    }

    saveDialogForm() {
        if (!this.state.isSubmited) {
            this.setState({ isSubmited: true })
        }
        if (!Validation.isFormValid(this.state.valid)) {
            return;
        }
        this.props.loadingBarActions.showLoadingSpiner()
        let paymentObject = {
            "name": this.state.dialogData.name,
            "price": this.state.dialogData.price,
            "itemId":this.state.dialogData.itemId
           }
        if (this.state.dialogData._id) {
            paymentObject.id = this.state.dialogData._id;
            this.props.paymentItemActions.paymentItemUpdate(paymentObject).then(data => {
                this.props.loadingBarActions.hideLoadingSpiner()
                if (!data.ok) {
                    if (data.error) {
                        this.context.notification('error', I18n.t('errors.' + data.error.errorCode));
                    } else {
                        this.context.notification('error', I18n.t('paymentItem.Update_paymentItem_fail'))
                    }
                } else {
                    this.context.notification('success', I18n.t('paymentItem.Update_paymentItem_success'))
                    this.closeDialogForm();
                    this.updatePaymentItemsList(this.state.activePage,this.state.filter);
                    this.setState({ isSubmited: false })
                }
            });
        } else {
            paymentObject.email = this.state.dialogData.email;
            this.props.paymentItemActions.paymentItemCreate(paymentObject).then(data => {
                this.props.loadingBarActions.hideLoadingSpiner()
                if (!data.ok) {
                    if (data.error) {
                        this.context.notification('error', I18n.t('errors.' + data.error.errorCode));
                    } else {
                        this.context.notification('error', I18n.t('paymentItem.Create_paymentItem_fail'))
                    }
                } else {
                    this.context.notification('success', I18n.t('paymentItem.Create_paymentItem_success'))
                    this.closeDialogForm();
                    this.updatePaymentItemsList(this.state.activePage,this.state.filter);
                    this.setState({ isSubmited: false })
                }
            });
        }
    }
    handleInpputChange(key,e) {
        this.state.dialogData[key] = e.target.value;
        this.setState({ dialogData: this.state.dialogData, dialogChanged: true })
    }

    handleAddButtonClick() {
        let paymentObject = {
            "name": "",
            "itemId":'',
            "price": 0
        }
        this.setState({ showDialog: true, dialogData: paymentObject, editable: true });
    }
    closeDialogForm() {
        this.setState({ showDialog: false, dialogData: null, dialogChanged: false, showConfirm: false, editable: false });
    }
    handleMenuClick(key, paymentItem) {
        switch (key) {
            case 'Edit': {
                this.props.paymentItemActions.paymentItemGetDetail({_id: paymentItem._id}).then(data=>{
                    if(data.ok && data.res){
                        this.setState({ showDialog: true, dialogData: data.res, dialogChanged: false, editable: true  });
                    }else{
                        this.context.notification('error', I18n.t('paymentItem.Load_paymentItem_fail'))
                    }
                })
                break;
            }
            case 'View': {
                this.props.paymentItemActions.paymentItemGetDetail({_id: paymentItem._id}).then(data=>{
                    if(data.ok && data.res){
                        this.setState({ showDialog: true, dialogData: data.res, dialogChanged: false, editable: false  });
                    }else{
                        this.context.notification('error', I18n.t('paymentItem.Load_paymentItem_fail'))
                    }
                })
                break;
            }
            case 'changeStatus': {
                this.setState({ editable: true })
                break;
            }
            case 'Delete': {
                this.setState({ showConfirm: true, dialogData: paymentItem });
                break;
            }
        }
    }

    handlePaginationSelect(eventKey) {
        this.updatePaymentItemsList(eventKey,this.state.filter);
    }

    handlePaginationChose(e) {
        this.updatePaymentItemsList(parseInt(e.target.value),this.state.filter);
    }

    handleNumItemsPerPageChange(e) {
        let numItemsPerPage = parseInt(e.target.value);
        let numPage = 1
        this.setState({
            numItemsPerPage: numItemsPerPage,
        }, function () {
            this.updatePaymentItemsList(numPage,this.state.filter);
        });
    }

    confirmDeleteUser() {
        this.props.paymentItemActions.paymentItemDelete({id: this.state.dialogData._id}).then(data => {
            if (data.ok) {
                this.closeDialogForm();
                this.updatePaymentItemsList(this.state.activePage,this.state.filter);
                this.context.notification('success', I18n.t('paymentItem.Delete_paymentItem_success'));
            } else {
                if (data.error) {
                    this.context.notification('error', I18n.t('errors.' + data.error.errorCode));
                } else {
                    this.context.notification('error', I18n.t('paymentItem.Delete_paymentItem_fail'))
                }
            }
        })
    }

    ValidatorCallback(id, valid, messages) {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    }
    handleFilter(type,e){
        this.state.filter[type] = e.target.value;
        if(timeoutID){
            clearTimeout(timeoutID);
        }
        timeoutID = setTimeout(()=>{
            this.updatePaymentItemsList(this.state.activePage,this.state.filter)
        },300)
        this.setState({filter:this.state.filter});
    }
    
    renderDataItemList() {
        const { handleMenuClick, changeStatus } = this;
        if (this.state.paymentItems && this.state.paymentItems.list.length > 0) {
            return this.state.paymentItems.list.map(paymentItem => {
                return <tr key={paymentItem._id}>
                    <td>
                        {
                            <a className="clickable" onClick={() => { handleMenuClick('View', paymentItem) }}>
                                {paymentItem.name}
                            </a>
                        }
                    </td>
                    <td>
                        {paymentItem.itemId}
                    </td>
                    <td>{paymentItem.price} {paymentItem.currency.iso}</td>
                    <td>
                        <DropdownButton title={<Translate value='paymentItem.Actions' />} pullRight id="bg-nested-dropdown" onSelect={(eventKey) => { handleMenuClick(eventKey, paymentItem) }}>
                            <MenuItem eventKey="Edit"><Translate value='paymentItem.Edit' /></MenuItem>
                            <MenuItem eventKey="Delete"><Translate value='paymentItem.Delete' /></MenuItem>
                        </DropdownButton>
                    </td>
                </tr>
            })
        } else {
            return <tr><td className='item-no-found' colSpan={5}><Translate value='messages.commonMessages.item_no_found' /></td></tr>
        }

    }
    render() {
        return (
            <div className='content'>
                <Modal show={this.state.showConfirm} backdrop={true} onHide={this.closeDialogForm}>
                    <Modal.Header closeButton>
                        <Modal.Title><Translate value='paymentItem.DELETE_PAYMENT_ITEM' /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Translate value='paymentItem.DELETE_CONFIRM' />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle='success' onClick={this.confirmDeleteUser} ><Translate value='paymentItem.Yes' /></Button>
                        <Button bsStyle="warning" onClick={this.closeDialogForm}><Translate value='paymentItem.Cancel' /></Button>
                    </Modal.Footer>
                </Modal>
                {
                    this.state.dialogData
                        && <Modal show={this.state.showDialog} backdrop={true} onHide={this.closeDialogForm}>
                            <Modal.Header closeButton>
                                <Modal.Title>{this.state.dialogData._id ?  this.state.editable ? <Translate value='paymentItem.EDIT_PAYMENT_ITEM' /> : <Translate value='paymentItem.DETAIL_PAYMENT_ITEM' className='upper' /> : <Translate value='paymentItem.ADD_PAYMENT_ITEM' />}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup validationState={!this.state.isSubmited ? null : (this.state.valid.name ? null : 'error')}>
                                    <ControlLabel><Translate value='paymentItem.Name' /><span className='require'>*</span></ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={(e)=>{this.handleInpputChange('name',e)}}
                                        value={this.state.dialogData ? this.state.dialogData.name : ''}
                                        disabled={!this.state.editable}
                                        placeholder={I18n.t("paymentItem.Name")}
                                    />
                                    <Validator id='name' callback={this.ValidatorCallback}>
                                        <ValidCase hide={!this.state.isSubmited} valid={!Validation.isStringEmpty(this.state.dialogData.name)} message={I18n.t('messages.commonMessages.Required_field')} />
                                    </Validator>
                                </FormGroup>
                                <FormGroup validationState={!this.state.isSubmited ? null : (this.state.valid.itemId ? null : 'error')}>
                                    <ControlLabel><Translate value='paymentItem.Console_Id' /><span className='require'>*</span></ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={(e)=>{this.handleInpputChange('itemId',e)}}
                                        value={this.state.dialogData ? this.state.dialogData.itemId : ''}
                                        disabled={!this.state.editable}
                                        placeholder={I18n.t("paymentItem.Google_console_Id")}
                                    />
                                    <Validator id='itemId' callback={this.ValidatorCallback}>
                                        <ValidCase hide={!this.state.isSubmited} valid={!Validation.isStringEmpty(this.state.dialogData.itemId)} message={I18n.t('messages.commonMessages.Required_field')} />
                                    </Validator>
                                </FormGroup>
                                <FormGroup validationState={!this.state.isSubmited ? null : (this.state.valid.price ? null : 'error')}>
                                    <ControlLabel><Translate value='paymentItem.Price' /><span className='require'>*</span></ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={(e)=>{this.handleInpputChange('price',e)}}
                                        onBlur={(e) => { textboxNumberHelper.onBlurHandle(e, (e)=>{this.handleInpputChange('price',e)}) }}
                                        onFocus={(e) => { textboxNumberHelper.onfocusHandle(e, (e)=>{this.handleInpputChange('price',e)}) }}
                                        value={this.state.dialogData ? this.state.dialogData.price : ''}
                                        disabled={!this.state.editable}
                                        placeholder={I18n.t("paymentItem.price")}
                                    />
                                    <Validator id='price' callback={this.ValidatorCallback}>
                                        <ValidCase hide={!this.state.isSubmited} valid={!Validation.isStringEmpty(this.state.dialogData.price)} message={I18n.t('messages.commonMessages.Required_field')} />
                                        <ValidCase hide={!this.state.isSubmited} valid={Validation.isGreaterThan(this.state.dialogData.price, 0)} message={I18n.t('messages.commonMessages.greater_or_equa').format(0)}
                                                />
                                    </Validator>
                                </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <ControlLabel>(<span className='require'>*</span>): <Translate value='paymentItem.Required_fields' /></ControlLabel>
                                {!this.props.permissions || this.props.permissions.actions ? this.state.editable ?
                                    <Button bsStyle='success' onClick={this.saveDialogForm}><Translate value='paymentItem.Save' /></Button> 
                                        : <Button bsStyle='success' onClick={(e) =>{this.handleMenuClick("changeStatus", e)}}>
                                        <Translate value='paymentItem.Edit' /></Button>
                                    : null
                                }
                                <Button bsStyle="warning" onClick={this.closeDialogForm}><Translate value='paymentItem.Cancel' /></Button>
                            </Modal.Footer>
                        </Modal>
                }
                <div className='row-fluid clearfix table-header-controls-container'>
                    {
                        !this.props.permissions || this.props.permissions.actions ?
                        <FormGroup className='table-header-control'>
                            <ButtonToolbar className='text-center header-button-group'>
                                <Button bsSize='lg' bsStyle='success' onClick={this.handleAddButtonClick}> <Glyphicon glyph='plus' /><Translate value='paymentItem.Add' /></Button>
                            </ButtonToolbar>    
                        </FormGroup> : ''
                    }
                </div>
                <TableHeader
                    data={this.state.paymentItems} isHeader={true}
                    name={''}
                    handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                    handlePaginationSelect={this.handlePaginationSelect}
                />
                <table className="table table-striped table-hover">
                    <thead>
                        <tr className=''>
                            <th><Translate value='paymentItem.Name' /></th>
                            <th><Translate value='paymentItem.Console_Id' /></th>
                            <th><Translate value='paymentItem.Price' /></th>
                            <th><Translate value='paymentItem.Actions' /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderDataItemList()}
                    </tbody>
                </table>
                <TableHeader
                    data={this.state.paymentItems} isHeader={false}
                    name={''}
                    handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                    handlePaginationSelect={this.handlePaginationSelect}
                />
            </div>
        )
    }
}

PaymentItem.contextTypes = {
    notification: PropTypes.func,
}

function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        socket: state.socket,
        paymentItems: state.paymentItems,
        permissions: state.menuHandle.modulePermission
    }
}

function mapDispatchToProps(dispatch) {
    return {
        paymentItemActions: bindActionCreators(paymentItemActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentItem);