export const SIDEBAR_COLLAPSED = 'SIDEBAR_COLLAPSED';
export const SIDEBAR_LINK_CLICKED = 'SIDEBAR_LINK_CLICKED';
export const CHANGE_PAGE_TITLE = 'CHANGE_PAGE_TITLE';
export const REQUEST_NEWBOOKING_FORM = 'REQUEST_NEWBOOKING_FORM';
export const BUILD_MENU = 'BUILD_MENU'
export const UPDATE_MENU = 'UPDATE_MENU'
export function sidebarCollapsedAction(collapsed){
	return{
		type:SIDEBAR_COLLAPSED,
		sidebarCollapsed: collapsed
	}
}
export function sidebarLinkClickAction(key){
	return{
		type:SIDEBAR_LINK_CLICKED,
		key: key
	}
}
export function changePageTitle(title){
	return{
		type:CHANGE_PAGE_TITLE,
		pagetitle: title
	}
}

export function buildMenu(isAdmin,roles,settings,callback){
	if(!isAdmin && roles==null){
		roles = roles = roles||[];
	}
	return{
		type:BUILD_MENU,
		data:{roles,settings,isAdmin},
		callback:callback
	}
}

export function updateMenu(url){
	return {
		type:UPDATE_MENU,
		data : url
	}
}

export function requestAddNewBookingForm(){
	return {type:REQUEST_NEWBOOKING_FORM}
}