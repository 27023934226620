import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import { i18nReducer } from 'react-redux-i18n';
//our reducers
import { routerReducer } from 'react-router-redux'
import menuHandle from '../reducers/AppMenuReducer'
import auth from '../reducers/auth'
import loadingBar from '../reducers/loadingBarReducer'
//list all reducers here for conbination
const rootReducer = combineReducers({
  menuHandle,
  auth,
  Intl,
  loadingBar,
  routing: routerReducer,
  i18n: i18nReducer,
});

const initialState = {};

export default function configureStore() {
  let store;
  if (module.hot || process.env.REACT_APP_DEV_MODE) {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(thunk, logger)
      )
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(applyMiddleware(thunk))
    );
  }
  return store;
}
