import {callApi,callApiBase,loadUserProfile, UrlBuilder} from '../utils/apiUtils'
import { paymentItemCreateApi,
        paymentItemDeleteApi,
        paymentItemGetDetailApi,
        paymentItemGetListApi,
        paymentItemUpdateApi} from '../constants/ApiConfigs'

export function paymentItemGetDetail(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        paymentItemGetDetailApi,
        config,
        null,
        null,
        null,
        true
    )
}

export function paymentItemGetList(){
    let config = {
        method: 'get'
    }
    return callApi(
        paymentItemGetListApi,
        config,
        null,
        null,
        null,
        true
    )
}

export function paymentItemCreate(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        paymentItemCreateApi,
        config,
        null,
        null,
        null,
        true
    )
}
export function paymentItemUpdate(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        paymentItemUpdateApi,
        config,
        null,
        null,
        null,
        true
    )
}
export function paymentItemDelete(options){
    let config = {
        method: 'DELETE',
        body:JSON.stringify(options)
    }
    return callApi(
        paymentItemDeleteApi,
        config,
        null,
        null,
        null,
        true
    )
}