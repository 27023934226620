import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate, I18n } from "react-redux-i18n";
import moment from "moment";
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
  Button,
  Radio,
  Glyphicon,
  ButtonToolbar,
  DropdownButton,
  MenuItem,
  Modal,
  ListGroup,
  ListGroupItem,
  Checkbox,
  Tab,
  Tabs,
  Alert,
  Pagination,
} from "react-bootstrap";
import CKEditor from "react-ckeditor-component";
import * as videoActions from "../../actions/videoActions";
import * as categoryActions from "../../actions/categoryActions";
import * as paymentItemActions from "../../actions/paymentItemActions";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import { Validator, ValidCase } from "../../components/validator";
import { Validation, secondsToTimeFormat } from "../../utils/commonFunctions";
import TableHeader from "../../components/table/TableHeader";
import stripHtml from "string-strip-html";
import videojs from "video.js";
import CcCheckbox from "../../components/ccCheckbox/CcCheckbox";
var validUrl = require("valid-url");
// var TimeFormat = require('hh-mm-ss')
var player = null,
  timeoutID = null;

class Video extends Component {
  constructor() {
    super();
    this.state = {
      videos: null,
      activePage: 0,
      numItemsPerPage: 20,
      detailItem: null,
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      sortType: -1,
      valid: {},
      isSubmitted: false,
      editable: false,
      filter: {},
    };
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
    this.handlePaginationChose = this.handlePaginationChose.bind(this);
    this.handleNumItemsPerPageChange =
      this.handleNumItemsPerPageChange.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.updateVideosList = this.updateVideosList.bind(this);
    this.updateCategorisList = this.updateCategorisList.bind(this);
    this.getpaymentItemsList = this.getpaymentItemsList.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.closeDialogForm = this.closeDialogForm.bind(this);
    this.saveDialogForm = this.saveDialogForm.bind(this);
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
    this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.removeThumbnailImageHandle =
      this.removeThumbnailImageHandle.bind(this);
    this.handleThumbnailImageChange =
      this.handleThumbnailImageChange.bind(this);
    this.handleDescriptionAfterPaste =
      this.handleDescriptionAfterPaste.bind(this);
    this.handleDescriptionOnChange = this.handleDescriptionOnChange.bind(this);
    this.handleCategoryChanged = this.handleCategoryChanged.bind(this);
    this.handleVideoTypeChanged = this.handleVideoTypeChanged.bind(this);
    this.handleVideoMetadataLoaded = this.handleVideoMetadataLoaded.bind(this);
    this.loadVideoPlayer = this.loadVideoPlayer.bind(this);
    this.handleURLFocused = this.handleURLFocused.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleonTopChange = this.handleonTopChange.bind(this);
  }
  componentDidMount() {
    this.updateVideosList();
    this.updateCategorisList();
  }

  updateVideosList(activePage, query = {}) {
    this.props.videoActions
      .videoGetList({
        query: query,
        page: activePage,
        limit: this.state.numItemsPerPage || 20,
        sort: {
          createdDate: -1,
        },
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.setState({ videos: data.res });
        }
      });
  }
  updateCategorisList() {
    this.props.categoryActions.categoryGetAll().then((data) => {
      if (data.ok && data.res) {
        this.state.categories = data.res.list;
      }
    });
  }
  getpaymentItemsList() {
    this.props.paymentItemActions.paymentItemGetList().then((data) => {
      if (data.ok && data.res) {
        this.state.paymentItems = data.res.list;
      }
    });
  }

  saveDialogForm() {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!Validation.isFormValid(this.state.valid)) {
      return;
    }
    this.props.loadingBarActions.showLoadingSpiner();
    let objectVideo = {
      title: this.state.dialogData.title,
      description: this.state.dialogData.description,
      categoryId: this.state.dialogData.categoryId,
      url: this.state.dialogData.url,
      posterBase64: this.state.dialogData.posterBase64,
      duration: this.state.dialogData.duration || 0,
      videoType: this.state.dialogData.videoType,
      paymentItemId: this.state.dialogData.paymentItemId,
      top: this.state.dialogData.top,
      isLive: this.state.dialogData.isLive,
    };
    if (this.state.dialogData._id) {
      objectVideo._id = this.state.dialogData._id;
      this.props.videoActions.videoUpdate(objectVideo).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("video.Update_video_fail")
            );
          }
        } else {
          this.context.notification(
            "success",
            I18n.t("video.Update_video_success")
          );
          this.closeDialogForm();
          this.updateVideosList(this.state.activePage);
          this.setState({ isSubmited: false });
        }
      });
    } else {
      this.props.videoActions.videoCreate(objectVideo).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("video.Create_video_fail")
            );
          }
        } else {
          this.context.notification(
            "success",
            I18n.t("video.Create_video_success")
          );
          this.closeDialogForm();
          this.updateVideosList(this.state.activePage);
          this.setState({ isSubmited: false });
        }
      });
    }
  }
  handleTextInputChange(key, e) {
    this.state.dialogData[key] = e.target.value;
    if (key == "url") {
      this.setState(
        { dialogData: this.state.dialogData, dialogChanged: true },
        () => {
          this.loadVideoPlayer(null, true);
        }
      );
    } else {
      this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
    }
  }
  handleURLFocused(e) {}
  handleFilter(type, e) {
    this.state.filter[type] = e.target.value;
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    timeoutID = setTimeout(() => {
      this.updateVideosList(this.state.activePage, this.state.filter);
    }, 300);
    this.setState({ filter: this.state.filter });
  }

  handleonTopChange(e) {
    this.state.dialogData.top = e.target.checked;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  }

  handleonIsLiveChange = (e) => {
    this.state.dialogData.isLive = e.target.checked;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };

  handleAddButtonClick() {
    this.getpaymentItemsList();
    let objectVideo = {
      title: "",
      description: "",
      categoryId: null,
      url: "",
      videoType: 0,
      top: true,
    };
    this.setState({
      showDialog: true,
      dialogData: objectVideo,
      editable: true,
    });
  }
  closeDialogForm() {
    this.setState({
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      editable: false,
    });
  }
  handleMenuClick(key, video) {
    player = null;
    switch (key) {
      case "Edit": {
        this.getpaymentItemsList();
        this.props.videoActions
          .videoGetDetail({ _id: video._id })
          .then((data) => {
            if (data.ok && data.res) {
              this.setState(
                {
                  showDialog: true,
                  dialogData: data.res,
                  dialogChanged: false,
                  editable: true,
                },
                () => {
                  this.loadVideoPlayer(null, true);
                }
              );
            } else {
              this.context.notification(
                "error",
                I18n.t("video.Load_video_fail")
              );
            }
          });
        break;
      }
      case "View": {
        this.getpaymentItemsList();
        this.props.videoActions
          .videoGetDetail({ _id: video._id })
          .then((data) => {
            if (data.ok && data.res) {
              this.setState(
                {
                  showDialog: true,
                  dialogData: data.res,
                  dialogChanged: false,
                  editable: false,
                },
                () => {
                  this.loadVideoPlayer(null, true);
                }
              );
            } else {
              this.context.notification(
                "error",
                I18n.t("video.Load_video_fail")
              );
            }
          });
        break;
      }
      case "changeStatus": {
        this.setState({ editable: true });
        break;
      }
      case "Delete": {
        this.setState({ showConfirm: true, dialogData: video });
        break;
      }
    }
  }

  handlePaginationSelect(eventKey) {
    this.updateVideosList(eventKey);
  }

  handlePaginationChose(e) {
    let page = parseInt(e.target.value);
    this.updateVideosList(parseInt(e.target.value));
  }

  handleNumItemsPerPageChange(e) {
    let numItemsPerPage = parseInt(e.target.value);
    let numPage = 0;
    this.setState(
      {
        numItemsPerPage: numItemsPerPage,
      },
      function () {
        this.updateVideosList(numPage);
      }
    );
  }

  confirmDeleteUser() {
    this.props.videoActions
      .videoDelete({
        id: this.state.dialogData._id,
      })
      .then((data) => {
        if (data.ok) {
          this.closeDialogForm();
          this.updateVideosList();
          this.context.notification(
            "success",
            I18n.t("video.Delete_video_success")
          );
        } else {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("video.Delete_video_fail")
            );
          }
        }
      });
  }
  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }
  removeThumbnailImageHandle() {
    this.state.dialogData.poster = null;
    this.state.dialogData.posterBase64 = null;
    this.state.dialogData.posterFile = null;
    this.setState({ carModel: this.state.dialogData });
  }
  handleThumbnailImageChange(e) {
    e.preventDefault();
    this.state.dialogData.posterBase64 = null;
    this.state.dialogData.posterFile = null;
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.state.dialogData.posterBase64 = reader.result;
      this.state.dialogData.posterFile = file;
      this.setState({ carModel: this.state.dialogData });
    };
    reader.readAsDataURL(file);
  }
  handleDescriptionOnChange(evt) {
    console.log("onChange fired with event info: ", evt);
    this.state.dialogData.description = evt.editor.getData();
    this.setState({ dialogData: this.state.dialogData });
  }
  handleCategoryChanged(e) {
    this.state.dialogData.categoryId = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  }
  handleVideoTypeChanged(e) {
    this.state.dialogData.videoType = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  }
  handleVideoMetadataLoaded(e) {
    console.log(e);
  }

  loadVideoPlayer(e, show = false) {
    if ((e && e.target && !!e.target.value) || show) {
      var that = this;
      setTimeout(() => {
        if (this.state.dialogData) {
          if (!player) {
            player = videojs(this.refs.player, {
              debug: true,
            });
            player.on(
              [
                "error",
                "loadstart",
                "play",
                "playing",
                "firstplay",
                "pause",
                "ended",
                "adplay",
                "adplaying",
                "adfirstplay",
                "adpause",
                "adended",
                "contentplay",
                "contentplaying",
                "contentfirstplay",
                "contentpause",
                "contentended",
                "contentupdate",
                "loadeddata",
                "loadedmetadata",
              ],
              function (e) {
                switch (e.type) {
                  case "loadedmetadata": {
                    that.state.dialogData.duration = Math.round(
                      player.duration()
                    );
                    that.setState({ dialogData: that.state.dialogData });
                    break;
                  }
                  case "error": {
                    that.state.dialogData.duration = 0;
                    that.setState({ dialogData: that.state.dialogData });
                    break;
                  }
                  default: {
                    break;
                  }
                }
              }
            );
          }
          player.src([
            { type: "video/mp4", src: this.state.dialogData.url },
            { type: "application/x-mpegURL", src: this.state.dialogData.url },
          ]);
          player.reset();
        }
      }, 1000);
    } else {
    }
  }

  handleDescriptionAfterPaste(evt) {
    console.log("afterPaste event called with event info: ", evt);
  }
  renderDataItemList() {
    const { handleMenuClick, changeStatus } = this;
    if (this.state.videos && this.state.videos.list.length > 0) {
      return this.state.videos.list.map((video) => {
        return (
          <tr key={video._id}>
            <td style={{ width: "60px" }}>
              <img
                src={video.thumbnail}
                style={{ maxHeight: "50px", height: "50px" }}
              />
            </td>
            <td>
              <p>
                <a
                  className="clickable"
                  onClick={() => {
                    handleMenuClick("View", video);
                  }}
                >
                  {video.title}
                </a>
              </p>
              <p>{secondsToTimeFormat(video.duration || 0)}</p>
            </td>
            <td>{video.categoryName}</td>
            <td>{stripHtml(video.description).substr(0, 200)}</td>
            <td>{moment(video.createdDate).format("DD/MM/YYYY HH:mm:ss")}</td>
            <td>
              <DropdownButton
                title={<Translate value="video.Actions" />}
                pullRight
                id="bg-nested-dropdown"
                onSelect={(eventKey) => {
                  handleMenuClick(eventKey, video);
                }}
              >
                <MenuItem eventKey="Edit">
                  <Translate value="video.Edit" />
                </MenuItem>
                <MenuItem eventKey="Delete">
                  <Translate value="video.Delete" />
                </MenuItem>
              </DropdownButton>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="item-no-found" colSpan={2}>
            <Translate value="messages.commonMessages.item_no_found" />
          </td>
        </tr>
      );
    }
  }
  render() {
    return (
      <div className="content">
        <Modal show={this.state.showConfirm} backdrop={true}>
          <Modal.Header closeButton onHide={this.closeDialogForm}>
            <Modal.Title>
              <Translate value="video.DELETE_VIDEO" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Translate value="video.DELETE_CONFIRM" />
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="success" onClick={this.confirmDeleteUser}>
              <Translate value="video.Yes" />
            </Button>
            <Button bsStyle="warning" onClick={this.closeDialogForm}>
              <Translate value="video.Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.dialogData && (
          <Modal
            show={this.state.showDialog}
            backdrop={false}
            onHide={this.closeDialogForm}
            dialogClassName="video-dialog"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.dialogData._id ? (
                  this.state.editable ? (
                    <Translate value="video.EDIT_VIDEO" />
                  ) : (
                    <Translate value="video.DETAIL_VIDEO" className="upper" />
                  )
                ) : (
                  <Translate value="video.ADD_VIDEO" />
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={12} lg={6}>
                  <FormGroup className="video-container">
                    {this.state.dialogData.poster ||
                    this.state.dialogData.posterBase64 ? (
                      <div className="image-container">
                        <img
                          className="model-poster"
                          src={
                            this.state.dialogData.poster ||
                            this.state.dialogData.posterBase64
                          }
                        />
                        {this.state.editable && (
                          <span
                            onClick={this.removeThumbnailImageHandle}
                            className="model-image-remove"
                          >
                            ×
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="upload-btn-wrapper">
                        <Button bsStyle="warning">
                          <i className="fa fa-upload" aria-hidden="true"></i>
                          <Translate value="video.Upload_an_image" />
                        </Button>
                        <FormControl
                          type="file"
                          label="Upload"
                          accept="image/*"
                          onChange={this.handleThumbnailImageChange}
                        />
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup
                    validationState={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.title
                        ? null
                        : "error"
                    }
                  >
                    <ControlLabel>
                      <Translate value="video.Title" />
                      <span className="require">*</span>
                    </ControlLabel>
                    <FormControl
                      type="text"
                      onChange={(e) => {
                        this.handleTextInputChange("title", e);
                      }}
                      value={
                        this.state.dialogData ? this.state.dialogData.title : ""
                      }
                      disabled={!this.state.editable}
                      placeholder={I18n.t("videoSetting.Title")}
                    />
                    <Validator id="title" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmited}
                        valid={
                          !Validation.isStringEmpty(this.state.dialogData.title)
                        }
                        message={I18n.t(
                          "messages.commonMessages.Required_field"
                        )}
                      />
                    </Validator>
                  </FormGroup>
                  <FormGroup
                    validationState={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.url
                        ? null
                        : "error"
                    }
                  >
                    <ControlLabel>
                      <Translate value="video.Video_HLS_URL" />
                      <span className="require">*</span>
                    </ControlLabel>
                    <FormControl
                      type="text"
                      onChange={(e) => {
                        this.handleTextInputChange("url", e);
                      }}
                      value={
                        this.state.dialogData ? this.state.dialogData.url : ""
                      }
                      onFocus={this.handleURLFocused}
                      onBlur={this.loadVideoPlayer}
                      disabled={!this.state.editable}
                      placeholder={I18n.t("videoSetting.url")}
                    />
                    <Validator id="url" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmited}
                        valid={
                          !Validation.isStringEmpty(this.state.dialogData.url)
                        }
                        message={I18n.t(
                          "messages.commonMessages.Required_field"
                        )}
                      />
                    </Validator>
                  </FormGroup>
                  <FormGroup
                    validationState={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.videoType === false
                        ? "error"
                        : null
                    }
                  >
                    <ControlLabel>
                      <Translate value="video.Video_type" />
                      <span className="require">*</span>
                    </ControlLabel>
                    <FormControl
                      disabled={!this.state.editable}
                      componentClass="select"
                      value={
                        this.state.dialogData
                          ? this.state.dialogData.videoType
                          : ""
                      }
                      onChange={this.handleVideoTypeChanged}
                    >
                      <option value="0">{I18n.t("video.Free_Video")}</option>
                      <option value="1">
                        {I18n.t("video.Subscribled_Video")}
                      </option>
                      <option value="2">{I18n.t("video.Pricing_Video")}</option>
                    </FormControl>
                    <Validator id="videoType" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmited}
                        valid={
                          !Validation.isStringEmpty(
                            this.state.dialogData.videoType
                          )
                        }
                        message={I18n.t(
                          "messages.commonMessages.Required_field"
                        )}
                      />
                    </Validator>
                  </FormGroup>
                  <FormGroup>
                    <CcCheckbox
                      disabled={!this.state.editable}
                      text={I18n.t("video.Set_Video_On_Top")}
                      checked={this.state.dialogData.top}
                      onChange={this.handleonTopChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} lg={6}>
                  <div className="video-container">
                    <video
                      ref="player"
                      className="video-js vjs-default-skin"
                      controls
                      autoplay
                      muted
                      width="568"
                      height="318"
                    >
                      <source
                        src={this.state.dialogData.url || ""}
                        type="application/x-mpegURL"
                      />
                      <source
                        src={this.state.dialogData.url || ""}
                        type="video/mp4"
                      />
                    </video>
                  </div>

                  <FormGroup
                    validationState={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.category === false
                        ? "error"
                        : null
                    }
                  >
                    <ControlLabel>
                      <Translate value="video.Category" />
                      <span className="require">*</span>
                    </ControlLabel>
                    <FormControl
                      disabled={!this.state.editable}
                      componentClass="select"
                      value={
                        this.state.dialogData
                          ? this.state.dialogData.categoryId
                          : ""
                      }
                      onChange={this.handleCategoryChanged}
                    >
                      <option value="">
                        {I18n.t("video.Select_category")}
                      </option>
                      {this.state.categories &&
                        this.state.categories.map((cate) => {
                          return (
                            <option key={cate._id} value={cate._id}>
                              {cate.name}
                            </option>
                          );
                        })}
                    </FormControl>
                    <Validator id="category" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmited}
                        valid={
                          !Validation.isStringEmpty(
                            this.state.dialogData.categoryId
                          )
                        }
                        message={I18n.t(
                          "messages.commonMessages.Required_field"
                        )}
                      />
                    </Validator>
                  </FormGroup>
                  <FormGroup>
                    <CcCheckbox
                      disabled={!this.state.editable}
                      text={I18n.t("video.Is_Live_Video")}
                      checked={this.state.dialogData.isLive}
                      onChange={this.handleonIsLiveChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>
                      <Translate value="video.Duration" />
                    </ControlLabel>
                    <FormControl
                      type="text"
                      onChange={(e) => {
                        this.handleTextInputChange("duration", e);
                      }}
                      value={
                        this.state.dialogData
                          ? secondsToTimeFormat(
                              this.state.dialogData.duration || 0
                            )
                          : ""
                      }
                      disabled={true}
                    />
                  </FormGroup>
                  {this.state.dialogData.videoType == 2 && (
                    <FormGroup
                      validationState={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.paymentItemId === false
                          ? "error"
                          : null
                      }
                    >
                      <ControlLabel>
                        <Translate value="video.Video_Price" />
                        <span className="require">*</span>
                      </ControlLabel>
                      <FormControl
                        disabled={!this.state.editable}
                        componentClass="select"
                        value={
                          this.state.dialogData
                            ? this.state.dialogData.paymentItemId
                            : ""
                        }
                        onChange={(e) => {
                          this.handleTextInputChange("paymentItemId", e);
                        }}
                      >
                        <option value="">
                          {I18n.t("video.Please_Select_price")}
                        </option>
                        {this.state.paymentItems &&
                          this.state.paymentItems.map((item) => {
                            return (
                              <option value={item._id}>
                                {item.currency.symbol}
                                {item.price} ({item.name})
                              </option>
                            );
                          })}
                      </FormControl>
                      <Validator
                        id="paymentItemId"
                        callback={this.ValidatorCallback}
                      >
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={
                            this.state.dialogData.videoType != 2 ||
                            !Validation.isStringEmpty(
                              this.state.dialogData.paymentItemId
                            )
                          }
                          message={I18n.t(
                            "messages.commonMessages.Required_field"
                          )}
                        />
                      </Validator>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <FormGroup>
                <ControlLabel>
                  <Translate value="video.Description" />
                  <span className="require">*</span>
                </ControlLabel>
                <CKEditor
                  disabled={!this.state.editable}
                  activeClass="p10"
                  content={
                    this.state.dialogData
                      ? this.state.dialogData.description
                      : ""
                  }
                  events={{
                    afterPaste: this.handleDescriptionAfterPaste,
                    change: this.handleDescriptionOnChange,
                  }}
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <ControlLabel>
                (<span className="require">*</span>):{" "}
                <Translate value="video.Required_fields" />
              </ControlLabel>
              {!this.props.permissions || this.props.permissions.actions ? (
                this.state.editable ? (
                  <Button bsStyle="success" onClick={this.saveDialogForm}>
                    <Translate value="video.Save" />
                  </Button>
                ) : (
                  <Button
                    bsStyle="success"
                    onClick={(e) => {
                      this.handleMenuClick("changeStatus", e);
                    }}
                  >
                    <Translate value="video.Edit" />
                  </Button>
                )
              ) : null}
              <Button bsStyle="warning" onClick={this.closeDialogForm}>
                <Translate value="video.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <div className="row-fluid clearfix table-header-controls-container">
          {!this.props.permissions || this.props.permissions.actions ? (
            <FormGroup className="table-header-control">
              <ButtonToolbar className="text-center header-button-group">
                <Button
                  bsStyle="success"
                  bsSize="lg"
                  onClick={this.handleAddButtonClick}
                >
                  {" "}
                  <Glyphicon glyph="plus" />
                  <Translate value="video.Add" />
                </Button>
              </ButtonToolbar>
            </FormGroup>
          ) : (
            ""
          )}
          <FormGroup className="table-header-control">
            <ControlLabel>
              <Translate value="video.Category" />
            </ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              onChange={(e) => {
                this.handleFilter("categoryId", e);
              }}
              value={this.state.filter.categoryId}
            >
              <option value="">{I18n.t("video.All")}</option>
              {this.state.categories &&
                this.state.categories.map((cate) => {
                  return <option value={cate._id}>{cate.name}</option>;
                })}
            </FormControl>
          </FormGroup>
          <FormGroup className="table-header-control">
            <ControlLabel>
              <Translate value="video.Key_words" />
            </ControlLabel>
            <FormControl
              type="text"
              placeholder="Enter text for fillter"
              onChange={(e) => {
                this.handleFilter("keyword", e);
              }}
              value={this.state.filter.keyword}
            />
          </FormGroup>
        </div>
        <TableHeader
          data={this.state.videos}
          isHeader={true}
          name={""}
          handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
          handlePaginationSelect={this.handlePaginationSelect}
        />
        <table className="table table-striped table-hover">
          <thead>
            <tr className="">
              <th style={{ width: "60px" }}>
                <Translate value="video.Thumbnail" />
              </th>
              <th>
                <Translate value="video.Title" />
              </th>
              <th>
                <Translate value="video.Category" />
              </th>
              <th>
                <Translate value="video.Description" />
              </th>
              <th>
                <Translate value="video.Created_date" />
              </th>
              <th>
                <Translate value="video.Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{this.renderDataItemList()}</tbody>
        </table>
        <TableHeader
          data={this.state.videos}
          isHeader={false}
          name={""}
          handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
          handlePaginationSelect={this.handlePaginationSelect}
        />
      </div>
    );
  }
}

Video.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    socket: state.socket,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    videoActions: bindActionCreators(videoActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    categoryActions: bindActionCreators(categoryActions, dispatch),
    paymentItemActions: bindActionCreators(paymentItemActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Video);
