import {
  callApi,
  saveUserToLocal,
  loadUserProfile,
  removeUserProfile,
  UrlBuilder
} from "../utils/apiUtils";

import {
  userLoginApi, userLogoutApi,
  userChangePasswordApi, userForgotPasswordApi, userEditProfileApi, userResetPasswordApi
} from '../constants/ApiConfigs'
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const FLEET_REQUEST = "FLEET_REQUEST";
export const FLEET_SUCCESS = "FLEET_SUCCESS";
export const FLEET_FAILURE = "FLEET_FAILURE";

export const CHANGE_FLEET = 'CHANGE_FLEET';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';

function loginRequest(user) {
  return {
    type: LOGIN_REQUEST,
    user
  };
}

function loginSuccess(payload) {
  saveUserToLocal(payload.res);
  return {
    type: LOGIN_SUCCESS,
    user: payload.res.user,
    token: payload.token,
    fleet: []
  };
}

function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error
  };
}

export function login(username, password, remember) {
  const config = {
    method: "post",
    body: JSON.stringify({
      email: username,
      password: password
    })
  };

  return callApi(
    userLoginApi,
    config,
    loginRequest(username),
    loginSuccess,
    loginFailure,
    true
  );
}

function logoutRequest(user) {
  return {
    type: LOGOUT_REQUEST,
    user
  };
}

function logoutSuccess(paylad) {
  removeUserProfile()
  return {
    type: LOGOUT_SUCCESS,
    user: null
  };
}

function logoutFailure(error) {
  removeUserProfile()
  return {
    type: LOGOUT_FAILURE,
    error
  };
}

export function logout(user) {
  const config = {
    method: "post",
  }
  return callApi(
    userLogoutApi,
    config,
    logoutRequest,
    logoutSuccess,
    logoutFailure
  );
}

function changePasswordSuccess() {
  let user = loadUserProfile();
  user.user.defaultPw = false;
  saveUserToLocal(user);
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    data: user
  };
}

export function changePassword(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    userChangePasswordApi,
    config,
    null,
    changePasswordSuccess,
    null,
    true
  );
}

export function resetPassword(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    userResetPasswordApi,
    config,
    null,
    null,
    null,
    true
  );
}

export function forgotPassword(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    userForgotPasswordApi,
    config,
    null,
    null,
    null,
    true
  );
}

export function editProfile(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    userEditProfileApi,
    config,
    null,
    () => {
      editProfileSuccess(options);
      return {
        type: EDIT_PROFILE_SUCCESS
      };
    },
    null,
    true
  );
}


function editProfileSuccess(data) {
  let user = loadUserProfile();
  user.user.firstName = data.firstName;
  user.user.lastName = data.lastName;
  user.user.email = data.email;
  user.user.address = data.address;
  saveUserToLocal(user);
}
