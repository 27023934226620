import './Login.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { Validation } from '../../utils/commonFunctions'
import { translations } from '../../constants/languages/languages'
import { setLocale, Translate, Localize, I18n } from 'react-redux-i18n';
import {
	Button,
	Form,
	Row,
	Col,
	FormControl,
	Checkbox,
	FormGroup,
	ControlLabel,
	InputGroup,
} from 'react-bootstrap';
import { Validator, ValidCase } from '../../components/validator'
import { login } from "../../actions/auth"
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox'
import Helmet from "react-helmet";
import logo512 from '../../constants/staticFiles/logo512.png'

class Login extends Component {
	constructor() {
		super();
		this.state = {
			username: '',
			password: '',
			rememeberMe: false,
			isSubmited: false,
			valid: {}
		};
		this.handleLogin = this.handleLogin.bind(this);
		this.languageSelected = this.languageSelected.bind(this);
		this.ValidatorCallback = this.ValidatorCallback.bind(this);
		this.handleUsernameChange = this.handleUsernameChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.handleRememberChange = this.handleRememberChange.bind(this);
	}

	componentWillMount() {
		if (this.props.user) {
			// logged in, let's show redirect if any, or show home
			try {
				const redirect = this.props.location.query.redirect;
				this.context.router.replace(redirect);
			} catch (err) {
				this.context.router.replace("/");
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.user) {
			// logged in, let's show redirect if any, or show home
			try {
				const redirect = this.props.location.query.redirect;
				this.context.router.replace(redirect);
			} catch (err) {
				this.context.router.replace("/");
			}
		}
	}

	handleLogin(event) {
		event.preventDefault();
		if (!this.state.isSubmited) {
			this.setState({ isSubmited: true })
		}
		if (!Validation.isFormValid(this.state.valid)) {
			return;
		} else {
			this.props.login(this.state.username, this.state.password, this.state.rememeberMe);
			this.setState({ password: '' })
		}
	}
	languageSelected(lang) {
		this.props.setLanguage(lang);
	}
	ValidatorCallback(id, valid, messages) {
		if (this.state.valid[id] != valid) {
			this.state.valid[id] = valid;
			this.setState({ valid: this.state.valid });
		}
	}
	handleUsernameChange(e) {
		this.setState({ username: e.target.value })
	}
	handlePasswordChange(e) {
		this.setState({ password: e.target.value })
	}
	handleRememberChange(e) {
		this.setState({ rememeberMe: e.target.checked })
	}
	render() {
		console.log(this.props.loginError)
		return (
			<div className={'login-body'} horizontal>
				<Helmet
					title={I18n.t('project.name')}
				/>
				<form className={'bg-image'} onSubmit={this.handleLogin}>
					<div horizontal className={'login-form mt-md-2 mt-lg-4'} >
						<div className='logo-login'>
							<img src={logo512} className='logo-img' />
						</div>
						<FormGroup validationState={!this.state.isSubmited ? null : (this.state.valid.username ? null : 'error')}>
							<input
								type="text"
								value={this.state.username}
								className="form-control"
								placeholder={I18n.t('login.Username')}
								required
								autoFocus
								className='form-control'
								onChange={this.handleUsernameChange}
							/>
							<Validator id='username' callback={this.ValidatorCallback}>
								<ValidCase hide={!this.state.isSubmited} valid={!Validation.isStringEmpty(this.state.username)} message={I18n.t('messages.commonMessages.Required_field')} />
								<ValidCase hide={!this.state.isSubmited} valid={Validation.validateEmail(this.state.username)} message={I18n.t('messages.commonMessages.Email_invalid')} />
							</Validator>
						</FormGroup>
						<FormGroup validationState={!this.state.password ? null : (this.state.valid.password ? null : 'error')}>
							<input
								type="password"
								value={this.state.password}
								className="form-control"
								placeholder={I18n.t('login.Password')}
								className='form-control'
								onChange={this.handlePasswordChange}
							/>
							<Validator id='password' callback={this.ValidatorCallback}>
								<ValidCase hide={!this.state.isSubmited} valid={!Validation.isStringEmpty(this.state.password)} message={I18n.t('messages.commonMessages.Required_field')} />
							</Validator>
						</FormGroup>
						{this.props.loginError ?
							<div className={"alert alert-danger"}>
								<strong>{this.props.loginError && this.props.loginError.errorCode ? I18n.t('errors.' + this.props.loginError.errorCode) : this.props.loginError.message}</strong>
							</div> : null
						}
						<FormGroup>
							<Row className='mr-t-10'>
								<div className='col-md-6 col-sm-12'>
									<CcCheckbox className='text-left' checked={this.state.rememeberMe} onChange={this.handleRememberChange} ref='rememberMe' text={I18n.t('login.Remember_me')} />
								</div>
								<div className='col-md-6 col-sm-12 pass-recovery'>
									<a href='/forgot-password' className='text-right'><label><Translate value='login.Forgot_your_password' /></label></a>
								</div>
							</Row>
						</FormGroup>

						<Button block bsStyle='success' type='submit' className='text-center login-button' onClick={this.handleLogin}><Translate value='login.Login' /></Button>
						<table className="table language-login  mr-t-10">
							<tr>
								<td className='text-right'>
									<ControlLabel>Language</ControlLabel>
								</td>
								<td className='text-left'>
									<FormControl className='mr-l-5' componentClass='select' value={this.props.language.translations[this.props.language.locale].flagCode} onChange={(e) => { this.languageSelected(e.target.value) }}>
										{Object.keys(translations).map((key) => {
											let lang = translations[key];
											return <option key={key} value={key}>{lang.name}</option>
										})}
									</FormControl>
								</td>
							</tr>
						</table>
					</div>

				</form>
			</div>
		)
	}
}

Login.contextTypes = {
	router: PropTypes.object.isRequired,
	store: PropTypes.object.isRequired
};

Login.propTypes = {
	user: PropTypes.object,
	loginError: PropTypes.object,
	location: PropTypes.object
};

function mapStateToProps(state) {
	const { auth, i18n } = state;
	if (auth) {
		return {
			user: auth.user,
			loginError: auth.loginError,
			language: i18n
		};
	}

	return { user: null };
}
function mapDispatchToProps(dispatch) {
	return {
		setLanguage: (lang) => {
			localStorage.setItem('language', lang)
			dispatch(setLocale(lang))
		},
		login: (username, pass, rememeberMe) => {
			dispatch(login(username, pass, rememeberMe))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);