import './Login.css';
import { Translate, I18n } from 'react-redux-i18n';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as loadingBarActions from '../../actions/loadingBarActions'
import { connect } from "react-redux";
import { Formik } from 'formik'
import * as yup from 'yup'
import {
	Button,
	FormGroup,
} from 'react-bootstrap';
import { resetPassword } from "../../actions/auth";
import Helmet from "react-helmet";
import { Form, HelpBlock } from 'react-bootstrap';


class ResetPassword extends Component {
	constructor() {
		super();
		this.state = {
			isSuccess: false,
		}
		this.handleresetPassword = this.handleresetPassword.bind(this);
	}

	componentWillMount() {
		if (this.props.user) {
			// logged in, let's show redirect if any, or show home
			try {
				const redirect = this.props.location.query.redirect;
				this.context.router.replace(redirect);
			} catch (err) {
				this.context.router.replace("/");
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.user) {
			// logged in, let's show redirect if any, or show home
			try {
				const redirect = this.props.location.query.redirect;
				this.context.router.replace(redirect);
			} catch (err) {
				this.context.router.replace("/");
			}
		}
	}

	handleresetPassword(values) {
		this.props.loadingBarActions.showLoadingSpiner();
		this.props.resetPassword({
			email: values.email, secureKey: values.secureKey, password: values.password
		})
			.then(data => {
				this.props.loadingBarActions.hideLoadingSpiner();
				console.log(data)
				if (data.res) {
					this.setState({
						isSuccess: true
					});
				} else if (data.error) {
					this.setState({
						resetPasswordMessage: I18n.t('errors.' + data.error.errorCode),
						resetPasswordStyle: 'danger'
					});
				}
			});
	}
	render() {
		const { isSuccess } = this.state
		// form validation schema
		const schema = yup.object().shape({
			email: yup.string().required('Username/Email is required').email('Enter a valid email'),
			secureKey: yup.string().required('Token is required'),
			password: yup.string().required('Password is required').min(4, 'Password is too short - should be 4 chars minimum.'),
			passwordConfirmation: yup.string().when('password', {
				is: (val) => (val && val.length > 0 ? true : false),
				then: yup
					.string()
					.required('Confirm Passwrod is required')
					.oneOf([yup.ref('password')], 'Confirm Password not match.')
			})
		})

		if (isSuccess) {
			return (
				<div className={'login-body'}>
					<div className="alert alert-success text-center">
						<h5>Your password has been reset successfuly. click on button to go to Login page</h5>
						<a className='btn btn-default btn-block' href='/'>Login</a>
					</div>
				</div>
			)
		}
		return (
			<div className={'login-body'}>
				<Helmet
					title={I18n.t('project.name')}
				/>
				<div className={'bg-image'}>
					<div className={'login-form mt-md-2 mt-lg-4'} >
						{this.state.resetPasswordMessage ?
							<div className={"alert alert-" + this.state.resetPasswordStyle}>
								<strong>{this.state.resetPasswordMessage}</strong>
							</div> : null
						}
						<Formik
							validationSchema={schema}
							onSubmit={(values, { setSubmitting }) => {
								this.handleresetPassword(values)
								setSubmitting(false)
							}}
							initialValues={{}}
						>
							{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
								<Form onSubmit={handleSubmit}>
									<FormGroup>
										<input
											onChange={handleChange}
											onBlur={handleBlur}
											type="email"
											value={values.email}
											name='email'
											className="form-control"
											placeholder={I18n.t("resetPassword.Username")}
											autoFocus
										/>
									</FormGroup>
									{errors.email && <HelpBlock bsStyle='danger'>{errors.email}</HelpBlock>}

									<FormGroup>
										<input
											onChange={handleChange}
											onBlur={handleBlur}
											type="text"
											value={values.secureKey}
											name='secureKey'
											className="form-control"
											placeholder={'Security Token'}
										/>
									</FormGroup>
									{errors.secureKey && <HelpBlock bsStyle='danger'>{errors.secureKey}</HelpBlock>}
									<FormGroup>
										<input
											onChange={handleChange}
											onBlur={handleBlur}
											type="password"
											value={values.password}
											name='password'
											className="form-control"
											placeholder={'Password'}
										/>
									</FormGroup>
									{errors.password && <HelpBlock bsStyle='danger'>{errors.password}</HelpBlock>}
									<FormGroup>
										<input
											onChange={handleChange}
											onBlur={handleBlur}
											type="password"
											value={values.passwordConfirmation}
											name='passwordConfirmation'
											className="form-control"
											placeholder={'Confirm Password'}
										/>
									</FormGroup>
									{errors.passwordConfirmation && <HelpBlock bsStyle='danger'>{errors.passwordConfirmation}</HelpBlock>}
									<Button block bsStyle='success' className='text-center login-button' type="submit">Reset Password</Button>
									<a className='btn btn-default btn-block' href='/'>Login</a>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		)
	}
}
function mapDispatchToProps(dispatch) {
	return {
		resetPassword: (options) => { return dispatch(resetPassword(options)) },
		loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
	}
}

export default connect(null, mapDispatchToProps)(ResetPassword);