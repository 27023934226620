import React, { Component } from 'react';
import './ccCheckbox.css'
class CcCheckbox extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { disabled, id, onChange, className, inputClassName, labelClassName, checked, text, children, name, value } = this.props 
        let htmlId = id || Math.random().toString();
        return (
            <div className={'form-group ' +(className||'')}> {/*table-select-check*/}
                <input type='checkbox'  
                        onChange={onChange} 
                        checked={checked}
                        disabled={disabled}
                        name={name}
                        value={value}
                        className={'cc-checkbox '+(inputClassName||'')}
                        id={htmlId}/>
                <label htmlFor={htmlId} className={labelClassName||''}>{text}</label>
                {children}
            </div>
        )
    }
}

export default CcCheckbox;