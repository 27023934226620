export const UGC_VIDEO_STATUS = [
  { label: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
  { label: "PENDING DISTRIBUTION", value: "PENDING_DISTRIBUTION" },
  { label: "DISTRIBUTING", value: "DISTRIBUTING" },
  { label: "DISTRIBUTION FAILED", value: "DISTRIBUTION_FAILED" },
  { label: "DISTRIBUTED", value: "DISTRIBUTED" },
  { label: "USER DELETED", value: "USER_DELETED" },
  { label: "ADMIN REJECTED", value: "ADMIN_REJECTED" },
  { label: "ADMIN DELETED", value: "ADMIN_DELETED" },
  { label: "ADMIN DELETED MARK", value: "ADMIN_DELETED_MARK" },
];
