import "isomorphic-fetch";
import { logout } from '../actions/auth'
import { loadingIncrease, loadingDecrease } from '../actions/loadingBarActions'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import FileSaver from 'file-saver';
const uuidv4 = require('uuid/v4');

export function checkStatus(response, config) {
  if (!response.ok) {
    const error = new Error(response.statusText);
    throw error;
  }
  if (config.fileName) {
    return response.blob();
  }
  return response;
}

export function parseJSON(response) {
  return response.json();
}
/**
 * A utility to call a restful service.
 *
 * @param url The restful service end point.
 * @param config The config object of the call. Can be null.
 * @param request The request action.
 * @param onRequestSuccess The callback function to create request success action.
 *                 The function expects response json payload as its argument.
 * @param onRequestFailure The callback function to create request failure action.
 *                 The function expects error as its argument.
 */
export function callApi(
  url,
  config,
  request,
  onRequestSuccess,
  onRequestFailure,
  showLoading
) {
  const user = loadUserProfile();
  config.headers = { "Accept": "application/json" }
  if (!config.isFormData) {
    config.headers["Content-Type"] = "application/json"
  }
  if (user) {
    config.headers["Authorization"] = user.token
  }
  return dispatch => {
    if (request) {
      dispatch(request);
    }
    if (showLoading) {
      dispatch(loadingIncrease())
    }
    return fetch(url, config)
      .then(response => {
        return checkStatus(response, config);
      })
      .then(response => {
        if (config.fileName) {
          return FileSaver.saveAs(response, config.fileName);
        }
        return parseJSON(response);
      })
      .then(json => {
        if (json.error) {
          if (onRequestFailure) {
            dispatch(onRequestFailure(json.error))
          }
        } else {
          json.ok = true;
          if (onRequestSuccess) {
            dispatch(onRequestSuccess(json));
          }
        }
        if (showLoading) {
          dispatch(loadingDecrease())
        }
        return json;
      })
      .catch(error => {
        const response = error.response;
        if (response === undefined) {
          if (onRequestFailure) {
            dispatch(onRequestFailure(error));
          }
        } else {
          error.status = response.status;
          error.statusText = response.statusText;
          response.json().then(json => {
            error.message = json;
            if (error.status == 401) {
              dispatch(logout());
              NotificationManager.error('Your session was ended or your have logged in on other device!', '', 5000, null, true);
              setTimeout(() => {
                window.location.reload();
              }, 10000)
            } else if (error.status == 403) {
              if (error.message.errorCode === 400003) {
                //NotificationManager.error('Permission denied!', '', 5000, null, true);
              } else {
                NotificationManager.error('Your fleet has been deactivated. Please contact our administrator!', '', 5000, null, true);
              }
            }
            if (onRequestFailure) {
              dispatch(onRequestFailure(error));
            }
          });
        }
        error.ok = false;
        if (showLoading) {
          dispatch(loadingDecrease());
        }
        return error;
      });
  };
}
export function callApiBase(
  url,
  config,
  request,
  onRequestSuccess,
  onRequestFailure,
  showLoading) {
  return dispatch => {
    if (request) {
      dispatch(request);
    }
    if (showLoading) {
      dispatch(loadingIncrease())
    }
    return fetch(url, config)
      .then(response => {
        return checkStatus(response, config);
      })
      .then(response => {
        return parseJSON(response);
      })
      .then(json => {
        if (json.error) {
          if (onRequestFailure) {
            dispatch(onRequestFailure(json.error))
          }
        } else {
          json.ok = true;
          if (onRequestSuccess) {
            dispatch(onRequestSuccess(json));
          }
        }
        if (showLoading) {
          dispatch(loadingDecrease())
        }
        return json;
      })
      .catch(error => {
        const response = error.response;
        if (response === undefined) {
          if (onRequestFailure) {
            dispatch(onRequestFailure(error));
          }
        } else {
          error.status = response.status;
          error.statusText = response.statusText;
          response.json().then(json => {
            error.message = json;
            if (onRequestFailure) {
              dispatch(onRequestFailure(error));
            }
          });
        }
        error.ok = false;
        if (showLoading) {
          dispatch(loadingDecrease());
        }
        return error;
      });
  };
}

export function saveUserToLocal(data) {
  data = JSON.stringify(data);
  localStorage.setItem('user', data);
}
export function loadUserProfile() {
  let user = localStorage.getItem('user');
  if (!user) return null;
  user = JSON.parse(user);
  return user;
}
export function removeUserProfile() {
  localStorage.removeItem('user');
}

export function UrlBuilder(url, options) {
  let esc = encodeURIComponent;
  let query = Object.keys(options)
    .map(k => esc(k) + '=' + esc(options[k]))
    .join('&');
  url += '?' + query;
  return url;
}

