import {
	SIDEBAR_COLLAPSED, 
	SIDEBAR_LINK_CLICKED,
	CHANGE_PAGE_TITLE,
	REQUEST_NEWBOOKING_FORM,
	BUILD_MENU,
	UPDATE_MENU
} from '../actions/sidebarCollapsedAction'
import {sidebarMenuConfigs} from '../constants/sidebarMenuConfigs'
function initialState(){
	return{
		sidebarCollapsed:false,
		menuList:sidebarMenuConfigs,
		key:'',
		selectedMainMenu:null,
		modulePermission:null
	}
}
export default function menuHandle(state=initialState(),action={}){
	switch(action.type){
		case SIDEBAR_COLLAPSED:{
			return {...state,sidebarCollapsed: action.sidebarCollapsed};
		}
		case SIDEBAR_LINK_CLICKED:{
			let menuList = checkActiveMenu(state.menuList,action.key)
			let selectedMenu = menuList.filter(mn=>{return mn.active==true})[0];
			return{
				...state,
				menuList: menuList,
				key:action.key,
				selectedMainMenu:selectedMenu,
				sidebarCollapsed:selectedMenu && selectedMenu.sub && selectedMenu.sub.length>0,
				modulePermission:getModulePermission(menuList.filter(mn=>{return mn.active==true})[0])
			}
		}
		case BUILD_MENU:{
			let menus = buildMenuForBaseRoles(sidebarMenuConfigs,action.data.roles,action.data.settings);
			let path = window.location.pathname.split('/');
			path = path.splice(1,path.length).join('.');
			state.menuList = checkActiveMenu(menus,path,0);
			if(!state.selectedMainMenu||(state.selectedMainMenu.show&&state.selectedMainMenu.enabled)){
				if(action.callback){
					//action.callback(state.menuList)
				}
				let selectedMenu = menus.filter(mn=>{return mn.active==true})[0];
				return {...state,menuList:menus,
						selectedMainMenu:selectedMenu,
						sidebarCollapsed:selectedMenu && selectedMenu.sub&&selectedMenu.sub.length>0,
						modulePermission:getModulePermission(state.menuList.filter(mn=>{return mn.active==true})[0])};
			}else{
				window.location.href ='/page-not-found';
			}
		}
		case UPDATE_MENU:{
			let path = action.data.splice(1,action.data.length).join('.');
			if(!path || path.length==0){
				return state;
			}
			state.menuList = checkActiveMenu(state.menuList,path,0);
			if(!state.selectedMainMenu||(state.selectedMainMenu.show&&state.selectedMainMenu.enabled)){
				if(action.callback){
					action.callback(state.menuList)
				}
				let selectedMenu = state.menuList.filter(mn=>{return mn.active==true})[0];
				return {...state,menuList:state.menuList,
						selectedMainMenu:selectedMenu,
						sidebarCollapsed:selectedMenu && selectedMenu.sub&&selectedMenu.sub.length>0,
						modulePermission:getModulePermission(state.menuList.filter(mn=>{return mn.active==true})[0])};
			}else{
				window.location.href ='/page-not-found';
			}
			return state;
		}
		default:
      		return state;
	}
}

function checkActiveMenu(menuList,key,pos=0){
	return menuList.map(mn=>{
		if(key.toLowerCase()==mn.key.toLowerCase()){
			if(mn.show&&mn.enabled){
				mn.active = true;
				if(mn.sub&&mn.sub.length>0){
					mn.sub = mn.sub.map(sub=>{
						return {...sub,active:false}
					})
					mn.sub[0].active = true;
				}
			}
			return mn;
		}else{
			let clickedMenu = key.split('.').splice(0,pos+1).join('.');
			if(clickedMenu.toLowerCase()==mn.key.toLowerCase()){
				if(mn.show&&mn.enabled){
					mn.active = true;
					if(mn.sub&&mn.sub.length>0){
						mn.sub = checkActiveMenu(mn.sub,key,pos+1);
					}
				}
				return mn;
			}else{
				mn.active = false;
				return mn
			}
		}
		
		
	})
}

function buildMenuForBaseRoles(menuList,roles,settings){
	return menuList.map(mn=>{
		if(mn.moduleSettingField && !settings[mn.moduleSettingField]){
			mn.show=false;
			mn.enabled=false;
			return mn;
		}
		if(mn.sub&&mn.sub.length>0){
			if(mn.subShowAll){
				mn.link = mn.sub[0].link
			}else{
				mn.sub = buildMenuForBaseRoles(mn.sub,roles,settings);
				mn.sub = mn.sub.filter(a=>{return a.show});
				if(mn.sub.length>0){
					mn.show=true;
					mn.enabled = true;
					mn.link = mn.sub[0].link
					return mn;
				}
			}
		}
		return checkmenuMapPermission(mn,roles,settings);
	});
}


function checkmenuMapPermission(menu,roles,settings){
	let menuName = menu.name.split(/[\s,_]+/).join('').toLowerCase();
	if(menu.moduleSettingField && !settings[menu.moduleSettingField]){
		menu.show=false;
		return menu;
	}
	if(!menu.enabled){
		menu.show=false;
		return menu;
	}
	if(roles==null){
		menu.show = true;
		return menu;
	}
	let role = roles.filter(r=>{
		let key = r.key.split(/[\s,_]+/).join('').toLowerCase();
		let name = r.name.split(/[\s,_]+/).join('').toLowerCase();
		
		let valid = false;
		if((key==menuName || name==menuName)&& menu.key.toLowerCase().indexOf('reports')<0){
			valid = true;
		}else{
			if(menu.key != menu.name){
				r.actions.every(a=>{
					let aname = a.name.split(/[\s,_]+/).join('').toLowerCase();
					if(aname==menuName&&a.isActive){
						valid = true;
						return false;
					}
					return true;
				})
			}
		}

		return valid;
	})
	if(!role||role.length==0||!role[0].actions){
		menu.show = false;
		return menu;
	}
	let view = role[0].actions.filter(r=>{
		return (r.name == 'View'||r.name.split(/[\s,_]+/).join('').toLowerCase()==menuName) && r.isActive;
	})
	if(view&&view.length>0){
		menu.show = true;
		menu.actions = role[0].actions;
	}

	if(menu.sub&&menu.sub.length>0){
		menu.sub = menu.sub.filter(m=>{
			return m.show;
		})
		if(menu.sub&&menu.sub.length>0){
			menu.show = true;
		}else{
			menu.show = false;
		}
	}

	//special case for rating report
	if(menu.key=='Reports.Rating' && settings.starsRating){
		menu.text = "Sidebar.Reports.Stars_rating"
	}
	return menu;
}

function getModulePermission(activatedMenu){
	if(activatedMenu){
		let actions = null;
		if(activatedMenu.actions){
			actions = {}
			activatedMenu.actions.map(ac=>{
				actions[ac.name.toLowerCase()] = ac.isActive;
			})
		}
		if(activatedMenu.sub){
			actions = getModulePermission(activatedMenu.sub.filter(mn=>{return mn.active==true})[0]);
		}
		return actions;
	}
	

}