import {callApi,callApiBase,loadUserProfile, UrlBuilder} from '../utils/apiUtils'
import { categoryCreateApi,
        categoryGeAllApi,
        categoryGetDetailApi,
        categoryDeleteApi,
        categoryUpdateApi} from '../constants/ApiConfigs'

export function categoryGetDetail(id){
    let config = {
        method: 'get'
    }
    return callApi(
        UrlBuilder(categoryGetDetailApi,{categoryId:id}),
        config,
        null,
        null,
        null,
        true
    )
}

export function categoryGetAll(id){
    let config = {
        method: 'get'
    }
    return callApi(
        categoryGeAllApi,
        config,
        null,
        null,
        null,
        true
    )
}

export function categoryCreate(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        categoryCreateApi,
        config,
        null,
        null,
        null,
        true
    )
}
export function categoryUpdate(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        categoryUpdateApi,
        config,
        null,
        null,
        null,
        true
    )
}
export function categorydelete(options){
    let config = {
        method: 'delete',
        body:JSON.stringify(options)
    }
    return callApi(
        categoryDeleteApi,
        config,
        null,
        null,
        null,
        true
    )
}