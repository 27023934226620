/* global google */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Helmet from "react-helmet";
import { setLocale, Translate, Localize, I18n } from "react-redux-i18n";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Loading from "../../components/loadingBar/loading";
import LoadingSpiner from "../../components/loadingSpiner/LoadingSpiner";
import { logout, loadUserFleet, changeFleet } from "../../actions/auth";
import "react-notifications/lib/notifications.css";
import "./App.css";
import "./react-datetime.css";
import * as menuActions from "../../actions/sidebarCollapsedAction";
import * as loadingActions from "../../actions/loadingBarActions";
import NotFound from "../misc/NotFound";
import PropTypes from "prop-types";

class App extends Component {
  constructor() {
    super();
    this.state = {
      AddNew: false,
      notification: [],
    };
    this.changeLanguage = this.changeLanguage.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.sideBarmenuClick = this.sideBarmenuClick.bind(this);
    this.initSystemData = this.initSystemData.bind(this);
    this.buildPageTitle = this.buildPageTitle.bind(this);
    this.backButtonHandle = this.backButtonHandle.bind(this);
    this.notification = this.notification.bind(this);
  }

  componentWillMount() {
    const { user, menuHandle } = this.props;
    const { router } = this.context;
    const path = this.props.location.pathname;
    // If this page is restricted, go to loginPage first.
    // (But pass on this page's path in order to redirect back upon login)
    if (!user) {
      router.push(`/login?redirect=${path}`);
    } else {
      if (user.defaultPw) {
        router.push(`/change-password`);
      } else {
        this.initSystemData();
      }
    }
  }
  initSystemData() {
    const path = this.props.location.pathname;
    const { user, menuHandle } = this.props;
    const { router } = this.context;
    this.backButtonHandle();
  }

  backButtonHandle(e) {
    if (window.location.pathname != "/") {
      let path = window.location.pathname.split("/");
      this.props.menuActions.updateMenu(path);
    } else {
      let menu = null;
      this.props.menuHandle.menuList.every((m) => {
        if (m.show) {
          menu = m;
          return false;
        }
        return true;
      });
      if (menu) {
        this.sideBarmenuClick(menu.key);
      }
    }
  }
  notification(type, title, message, clickHandler) {
    switch (type) {
      case "info":
        NotificationManager.info(message, title, 5000, clickHandler, true);
        break;
      case "success":
        NotificationManager.success(message, title, 5000, clickHandler, true);
        break;
      case "warning":
        NotificationManager.warning(message, title, 5000, clickHandler, true);
        break;
      case "error":
        NotificationManager.error(message, title, 5000, clickHandler, true);
        break;
    }
  }
  getChildContext() {
    return {
      notification: this.notification,
      currenLanguage: this.props.language,
      user: this.props.user,
    };
  }
  sidebarCollapsedClickHandle() {
    this.props.menuActions.sidebarCollapsedAction(!this.props.sidebarCollapsed);
  }
  sideBarmenuClick(key) {
    this.props.menuActions.sidebarLinkClickAction(key);
  }

  changeLanguage(lang) {
    this.props.setLanguage(lang);
  }
  handleLogout() {
    const { user } = this.props;
    this.props.logout(user);
  }
  buildPageTitle(menu) {
    let title = "";
    if (menu) {
      title += I18n.t(menu.text);
      if (menu.sub && menu.sub.length > 0) {
        let sub = menu.sub.filter((mn) => {
          return mn.active && mn.show;
        });
        sub = sub[0] || menu.sub[0];
        title += "/" + this.buildPageTitle(sub);
      }
    }
    return title;
  }

  CheckAccessablePermission(menu) {
    let isAccessable = false;
    if (menu.sub) {
      menu.sub.every((m) => {
        if (m.active) {
          isAccessable = true;
          return false;
        }
        return true;
      });
    }
    return isAccessable;
  }
  render() {
    let isViewProfile;
    let isViewChangePass;
    if (this.props.location.pathname === "/profile") {
      isViewProfile = true;
    }
    if (this.props.location.pathname === "/change-password") {
      isViewChangePass = true;
    }
    return (
      <div className="fill" tabIndex="-1">
        <Helmet
          title={
            I18n.t("project.name") +
            this.buildPageTitle(this.props.menuHandle.selectedMainMenu)
          }
        />
        <Loading
          show={this.props.loadingBar.total > 0}
          color="red"
          change={true}
          showSpinner={true}
        />

        <div className="fill">
          <Header
            title={
              isViewChangePass
                ? I18n.t("userProfile.Change_password")
                : isViewProfile
                ? I18n.t("userProfile.My_account")
                : this.buildPageTitle(this.props.menuHandle.selectedMainMenu)
            }
            languageSelect={this.changeLanguage}
            logout={this.handleLogout}
            router={this.props.router}
          />
          <Sidebar
            collapsed={this.props.sidebarCollapsed}
            collapedClickHandle={this.sidebarCollapsedClickHandle.bind(this)}
            menuList={
              this.props.defaultPw ? [] : this.props.menuHandle.menuList
            }
            onMenuClick={this.sideBarmenuClick}
            language={this.props.language}
          />
          <div
            id="page-content"
            className={this.props.sidebarCollapsed ? "collapsed fill" : "fill"}
          >
            {this.props.menuHandle.selectedMainMenu &&
            this.props.menuHandle.selectedMainMenu.sub &&
            this.props.menuHandle.selectedMainMenu.sub.length > 0 ? (
              <div className="have-submenu fill">
                <Sidebar
                  collapedClickHandle={this.sidebarCollapsedClickHandle.bind(
                    this
                  )}
                  menuList={this.props.menuHandle.selectedMainMenu.sub}
                  onMenuClick={this.sideBarmenuClick}
                  sub={true}
                />
                <div className="content-with-submenu fill">
                  {this.props.children}
                </div>
              </div>
            ) : (
              this.props.children
            )}
          </div>
        </div>
        <LoadingSpiner show={this.props.loadingBar.isShowSpiner} />
      </div>
      // <div className="cssload-thecube">
      //   <div className="cssload-cube cssload-c1"></div>
      //   <div className="cssload-cube cssload-c2"></div>
      //   <div className="cssload-cube cssload-c4"></div>
      //   <div className="cssload-cube cssload-c3"></div>
      // </div>
    );
  }
}

App.propTypes = {};

App.contextTypes = {
  router: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};

App.childContextTypes = {
  notification: PropTypes.func,
  currenLanguage: PropTypes.object,
  user: PropTypes.object,
  socket: PropTypes.object,
  reloadCueAnhMap: PropTypes.func,
};

function mapStateToProps(state) {
  const { menuHandle, i18n, auth, newBooking, loadingBar, notification } =
    state;
  if (menuHandle) {
    return {
      sidebarCollapsed: menuHandle.sidebarCollapsed,
      menuHandle: menuHandle,
      pagetitle: menuHandle.pagetitle,
      language: i18n,
      user: auth.user,
      loadingBar: loadingBar,
      notis: notification,
      commonData: state.commonData,
    };
  } else {
    return {
      sidebarCollapsed: false,
      menuHandle: {},
      pagetitle: "Home",
      language: null,
      selectedFleet: auth.selectedFleet,
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    menuActions: bindActionCreators(menuActions, dispatch),
    setLanguage: (lang) => {
      localStorage.setItem("language", lang);
      dispatch(setLocale(lang));
    },
    logout: () => dispatch(logout()),
    loadingActions: bindActionCreators(loadingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
