import React, { Component } from "react";
import {
  Button,
  Col,
  ControlLabel,
  DropdownButton,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  MenuItem,
  Modal,
  Row,
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import stripHtml from "string-strip-html";
import { secondsToTimeFormat } from "../../utils/commonFunctions";
import moment from "moment";
import * as ugcVideoActions from "../../actions/ugcVideoAction";
import * as categoryActions from "../../actions/categoryActions";
import * as ugcCommentActions from "../../actions/ugcCommentAction";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TableHeader from "../../components/table/TableHeader";
import videojs from "video.js";
import CKEditor from "react-ckeditor-component";
import * as yup from "yup";
import { UGC_VIDEO_STATUS } from "./../../constants/ugcStatus";
import { Formik } from "formik";

const schema = yup.object().shape({
  title: yup.string().required("Title is required!"),
  description: yup.string().required("Description is required!"),
  categoryId: yup.string().required("Category is required!"),
  url: yup.string().required("Url is required!"),
  status: yup.string().required("Status is required!"),
});

var player = null;
class Ugcvideo extends Component {
  constructor() {
    super();
    this.state = {
      activePage: 0,
      numItemsPerPage: 20,
      ugcVideos: null,
      dialogData: false,
      showDialog: false,
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.loadVideoPlayer = this.loadVideoPlayer.bind(this);
    this.closeDialogForm = this.closeDialogForm.bind(this);
    this.removeThumbnailImageHandle =
      this.removeThumbnailImageHandle.bind(this);
    this.handleThumbnailImageChange =
      this.handleThumbnailImageChange.bind(this);
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
    this.onComment = this.onComment.bind(this);
    this.videoRef = React.createRef(null);
    this.submitButton = React.createRef(null);
  }
  componentDidMount() {
    this.fetchListUgcVideos();
    this.fetchCategoryList();
  }
  fetchListUgcVideos(activePage, query = {}) {
    this.props.ugcVideoActions
      .ugcVideoGetList({
        query: query,
        page: activePage,
        limit: this.state.numItemsPerPage || 20,
        sort: {
          createdDate: -1,
        },
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.setState({ ugcVideos: data.res });
        }
      });
  }
  handleTextInputChange(key, e) {
    if (key == "url") {
      this.setState(
        { dialogData: this.state.dialogData, dialogChanged: true },
        () => {
          this.loadVideoPlayer(null, true);
        }
      );
    } else {
      this.setState({ comment: e.target.value });
    }
  }
  fetchCategoryList() {
    this.props.categoryActions.categoryGetAll().then((data) => {
      if (data.ok && data.res) {
        this.state.categories = data.res.list;
      }
    });
  }
  handleNumItemsPerPageChange(e) {
    let numItemsPerPage = parseInt(e.target.value);
    let numPage = 0;
    this.setState(
      {
        numItemsPerPage: numItemsPerPage,
      },
      function () {
        this.fetchListUgcVideos(numPage);
      }
    );
  }
  handlePaginationSelect(eventKey) {
    this.fetchListUgcVideos(eventKey);
  }
  loadVideoPlayer(e, show = false) {
    if ((e && e.target && !!e.target.value) || show) {
      var that = this;
      setTimeout(() => {
        if (this.state.dialogData) {
          if (!player) {
            player = videojs(this.videoRef.current, {
              debug: true,
            });
            player.on(
              [
                "error",
                "loadstart",
                "play",
                "playing",
                "firstplay",
                "pause",
                "ended",
                "adplay",
                "adplaying",
                "adfirstplay",
                "adpause",
                "adended",
                "contentplay",
                "contentplaying",
                "contentfirstplay",
                "contentpause",
                "contentended",
                "contentupdate",
                "loadeddata",
                "loadedmetadata",
              ],
              function (e) {
                switch (e.type) {
                  case "loadedmetadata": {
                    that.state.dialogData.duration = Math.round(
                      player.duration()
                    );
                    that.setState({ dialogData: that.state.dialogData });
                    break;
                  }
                  case "error": {
                    that.state.dialogData.duration = 0;
                    that.setState({ dialogData: that.state.dialogData });
                    break;
                  }
                  default: {
                    break;
                  }
                }
              }
            );
          }
          player.src([
            { type: "video/mp4", src: this.state.dialogData.url },
            { type: "application/x-mpegURL", src: this.state.dialogData.url },
          ]);
          player.reset();
        }
      }, 1000);
    } else {
    }
  }
  removeThumbnailImageHandle() {
    this.state.dialogData.poster = null;
    this.state.dialogData.posterBase64 = null;
    this.state.dialogData.posterFile = null;
    this.setState({ carModel: this.state.dialogData });
  }
  handleThumbnailImageChange(e) {
    e.preventDefault();
    this.state.dialogData.posterBase64 = null;
    this.state.dialogData.posterFile = null;
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.state.dialogData.posterBase64 = reader.result;
      this.state.dialogData.posterFile = file;
      this.setState({ carModel: this.state.dialogData });
    };
    reader.readAsDataURL(file);
  }
  handleMenuClick(key, video) {
    player = null;
    switch (key) {
      case "View": {
        this.props.ugcVideoActions
          .ugcVideoGetDetail({ _id: video._id })
          .then((data) => {
            if (data.ok && data.res) {
              this.setState(
                {
                  showDialog: true,
                  dialogData: data.res,
                  dialogChanged: false,
                },
                () => {
                  this.loadVideoPlayer(null, true);
                  this.props.ugcCommentActions
                    .ugcGetComments(video._id)
                    .then((data) => {
                      if (data.ok && data.res) {
                        this.setState({ comments: data.res });
                      }
                    });
                }
              );
            } else {
              this.context.notification(
                "error",
                I18n.t("video.Load_video_fail")
              );
            }
          });
        break;
      }
      case "changeStatus": {
        this.setState({ editable: true });
        break;
      }
    }
  }
  renderDataItemList() {
    const { handleMenuClick, changeStatus } = this;
    if (this.state.ugcVideos && this.state.ugcVideos.list.length > 0) {
      return this.state.ugcVideos.list.map((video) => {
        return (
          <tr key={video._id}>
            <td style={{ width: "60px" }}>
              <img
                src={video.thumbnail}
                style={{ maxHeight: "50px", height: "50px" }}
              />
            </td>
            <td>
              <p>
                <a
                  className="clickable"
                  onClick={() => {
                    handleMenuClick("View", video);
                  }}
                >
                  {video.title}
                </a>
              </p>
              <p>{secondsToTimeFormat(video.duration || 0)}</p>
            </td>
            <td>{video.categoryName}</td>
            <td>{stripHtml(video.description).substr(0, 200)}</td>
            <td>{moment(video.createdDate).format("DD/MM/YYYY HH:mm:ss")}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="item-no-found" colSpan={2}>
            <Translate value="messages.commonMessages.item_no_found" />
          </td>
        </tr>
      );
    }
  }
  closeDialogForm() {
    this.setState({
      showDialog: false,
      dialogData: null,
      showConfirm: false,
      categories: null,
      editable: false,
      comment: null,
      comments: null,
    });
  }

  onFormSubmit(values) {
    const payload = {
      _id: values._id,
      title: values.title,
      description: values.description,
      categoryId: values.categoryId,
      url: values.url,
      status: values.status,
      posterBase64: values.posterBase64,
    };
    this.props.ugcVideoActions.ugcVideoUpdate(payload).then((res) => {
      this.props.loadingBarActions.hideLoadingSpiner();
      if (!res.ok) {
        if (res.error) {
          // this.context.notification(
          //   "error",
          //   I18n.t("errors." + res.error.errorCode)
          // );
        } else {
          // this.context.notification("error", I18n.t("video.Update_video_fail"));
        }
      } else {
        // this.context.notification(
        //   "success",
        //   I18n.t("video.Update_video_success")
        // );
        this.closeDialogForm();
        this.fetchListUgcVideos(this.state.activePage);
        this.setState({ isSubmited: false });
      }
    });
  }

  onComment() {
    if (this.state.comment && this.state.dialogData._id) {
      this.props.ugcCommentActions
        .ugcCommentCreate({
          content: this.state.comment,
          ugcVideoId: this.state.dialogData._id,
        })
        .then((res) => {
          if (res.ok) {
            // this.context.notification(
            //   "success",
            //   I18n.t("video.comment_success")
            // );
            this.closeDialogForm();
            this.fetchListUgcVideos(this.state.activePage);
          }
        });
    }
  }

  render() {
    const {
      dialogData,
      showDialog,
      editable,
      ugcVideos,
      comments,
      categories,
    } = this.state;
    const { permissions } = this.props;
    return (
      <div className="content">
        {dialogData && (
          <Modal
            show={showDialog}
            backdrop={false}
            onHide={this.closeDialogForm}
            dialogClassName="video-dialog"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {dialogData._id && (
                  <Translate value="video.DETAIL_VIDEO" className="upper" />
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={dialogData}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) => {
                  this.onFormSubmit(values);
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12} lg={6}>
                        <FormGroup className="video-container">
                          {dialogData.poster || dialogData.posterBase64 ? (
                            <div className="image-container">
                              <img
                                className="model-poster"
                                src={
                                  dialogData.poster || dialogData.posterBase64
                                }
                                style={{
                                  objectFit: "cover",
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                              {editable && (
                                <span
                                  onClick={this.removeThumbnailImageHandle}
                                  className="model-image-remove"
                                >
                                  ×
                                </span>
                              )}
                            </div>
                          ) : (
                            <div className="upload-btn-wrapper">
                              <Button bsStyle="warning">
                                <i
                                  className="fa fa-upload"
                                  aria-hidden="true"
                                ></i>
                                <Translate value="video.Upload_an_image" />
                              </Button>
                              <FormControl
                                type="file"
                                label="Upload"
                                accept="image/*"
                                onChange={this.handleThumbnailImageChange}
                              />
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={12} lg={6}>
                        <div className="video-container">
                          <video
                            ref={this.videoRef}
                            className="video-js vjs-default-skin"
                            controls
                            autoPlay
                            muted
                            width="568"
                            height="318"
                          >
                            <source
                              src={dialogData.url || ""}
                              type="application/x-mpegURL"
                            />
                            <source
                              src={dialogData.url || ""}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={6}>
                        <FormGroup
                          validationState={errors.title ? "error" : null}
                        >
                          <ControlLabel>
                            <Translate value="video.Title" />
                            <span className="require">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="title"
                            onChange={handleChange}
                            value={values.title}
                            disabled={!editable}
                            placeholder={I18n.t("videoSetting.Title")}
                          />
                          {errors.title && (
                            <HelpBlock>{errors.title}</HelpBlock>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            <Translate value="video.Duration" />
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="duration"
                            onChange={handleChange}
                            value={secondsToTimeFormat(values.duration)}
                            disabled={true}
                          />
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            <Translate value="video.Status" />
                          </ControlLabel>
                          <FormControl
                            disabled
                            componentClass="select"
                            value={values.status}
                          >
                            <option value="">Please select one</option>
                            {UGC_VIDEO_STATUS.map((status, index) => (
                              <option key={index} value={status.value}>
                                {status.label}
                              </option>
                            ))}
                          </FormControl>
                          {errors.status && (
                            <HelpBlock>{errors.status}</HelpBlock>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={12} lg={6}>
                        <FormGroup
                          validationState={errors.url ? "error" : null}
                        >
                          <ControlLabel>
                            <Translate value="video.Video_HLS_URL" />
                            <span className="require">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="url"
                            onChange={(e) => {
                              setFieldValue("url", e.target.value);
                              this.handleTextInputChange("url", e);
                            }}
                            value={values.url}
                            onBlur={this.loadVideoPlayer}
                            disabled={!editable}
                            placeholder={I18n.t("videoSetting.url")}
                          />
                          {errors.url && <HelpBlock>{errors.url}</HelpBlock>}
                        </FormGroup>
                        <FormGroup
                          validationState={errors.categoryId ? "error" : null}
                        >
                          <ControlLabel>
                            <Translate value="video.Category" />
                            <span className="require">*</span>
                          </ControlLabel>
                          <FormControl
                            disabled={!editable}
                            componentClass="select"
                            value={values.categoryId}
                            onChange={handleChange}
                            name="categoryId"
                          >
                            <option value="">
                              {I18n.t("video.Select_category")}
                            </option>
                            {categories &&
                              categories.map((cate) => {
                                return (
                                  <option key={cate._id} value={cate._id}>
                                    {cate.name}
                                  </option>
                                );
                              })}
                          </FormControl>
                          {errors.categoryId && (
                            <HelpBlock>{errors.categoryId}</HelpBlock>
                          )}
                        </FormGroup>
                        <FormGroup
                          validationState={errors.status ? "error" : null}
                        >
                          <ControlLabel>
                            Action
                            <span className="require">*</span>
                          </ControlLabel>
                          <FormControl
                            disabled={!editable}
                            componentClass="select"
                            onChange={handleChange}
                            name="status"
                          >
                            <option value="">Please select one</option>
                            <option value="DISTRIBUTED">
                              {I18n.t("ugcVideoAction.admin_approve")}
                            </option>
                            <option value="ADMIN_REJECTED">
                              {I18n.t("ugcVideoAction.admin_reject")}
                            </option>
                            <option value="ADMIN_DELETED">
                              {I18n.t("ugcVideoAction.admin_delete")}
                            </option>
                          </FormControl>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12}>
                        <FormGroup
                          validationState={errors.description ? "error" : null}
                        >
                          <ControlLabel>
                            <Translate value="video.Description" />
                            <span className="require">*</span>
                          </ControlLabel>
                          <CKEditor
                            disabled={!editable}
                            activeClass="p10"
                            name="description"
                            content={values.description}
                            events={{
                              afterPaste: this.handleDescriptionAfterPaste,
                              change: (evt) =>
                                setFieldValue(
                                  "description",
                                  evt.editor.getData()
                                ),
                            }}
                          />
                        </FormGroup>
                        {errors.description && (
                          <HelpBlock>{errors.description}</HelpBlock>
                        )}
                      </Col>
                    </Row>
                    <button
                      type="submit"
                      style={{ display: "none" }}
                      ref={this.submitButton}
                    >
                      Save
                    </button>
                  </Form>
                )}
              </Formik>
              <Row>
                <Col md={12} lg={12}>
                  <ControlLabel>List Comment</ControlLabel>
                  <ul>
                    {comments &&
                      comments.map((comment) => (
                        <li>
                          {comment.user.name}: {comment.content}
                        </li>
                      ))}
                  </ul>
                </Col>
                <Col md={12} lg={12}>
                  <FormGroup>
                    <ControlLabel>
                      <Translate value="video.Comment" />
                    </ControlLabel>
                    <FormControl
                      componentClass="textarea"
                      name="comment"
                      onChange={(e) => this.handleTextInputChange("comment", e)}
                      placeholder={I18n.t("videoSetting.Comment")}
                    />
                  </FormGroup>
                  <Button
                    bsStyle="primary"
                    style={{ float: "right" }}
                    onClick={this.onComment}
                  >
                    {I18n.t("videoSetting.Comment")}
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <ControlLabel>
                (<span className="require">*</span>):{" "}
                <Translate value="video.Required_fields" />
              </ControlLabel>
              {!permissions || permissions.actions ? (
                editable ? (
                  <Button
                    bsStyle="success"
                    onClick={() => this.submitButton.current.click()}
                  >
                    <Translate value="video.Save" />
                  </Button>
                ) : (
                  <Button
                    bsStyle="success"
                    onClick={(e) => {
                      this.handleMenuClick("changeStatus", e);
                    }}
                  >
                    <Translate value="video.Edit" />
                  </Button>
                )
              ) : null}
              <Button bsStyle="warning" onClick={this.closeDialogForm}>
                <Translate value="video.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <TableHeader
          data={ugcVideos}
          isHeader={true}
          name={""}
          handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
          handlePaginationSelect={this.handlePaginationSelect}
        />
        <table className="table table-striped table-hover">
          <thead>
            <tr className="">
              <th style={{ width: "60px" }}>
                <Translate value="video.Thumbnail" />
              </th>
              <th>
                <Translate value="video.Title" />
              </th>
              <th>
                <Translate value="video.Category" />
              </th>
              <th>
                <Translate value="video.Description" />
              </th>
              <th>
                <Translate value="video.Created_date" />
              </th>
            </tr>
          </thead>
          <tbody>{this.renderDataItemList()}</tbody>
        </table>
        <TableHeader
          data={ugcVideos}
          isHeader={false}
          name={""}
          handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
          handlePaginationSelect={this.handlePaginationSelect}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    socket: state.socket,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ugcVideoActions: bindActionCreators(ugcVideoActions, dispatch),
    ugcCommentActions: bindActionCreators(ugcCommentActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    categoryActions: bindActionCreators(categoryActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Ugcvideo);
