import createClass from 'create-react-class'
var React = require('react');
var ReactScriptLoaderMixin = require('react-script-loader').ReactScriptLoaderMixin;
var widgetId;
var Captcha = createClass({
    mixins: [ReactScriptLoaderMixin],
    getInitialState: function() {
        return {
            scriptLoading: true,
            scriptLoadError: false,
        };
    },

    componentDidMount: function() {
      const sitekey = this.props.sitekey;
      const theme = this.props.theme;
      const type = this.props.type;
      const callback = this.props.callback;
      const expiredCallback = this.props.expiredCallback;
      window.onloadCallback = function() {
           widgetId = window.grecaptcha.render('captcha', {
            'sitekey' : sitekey,
            'callback' : callback,
            'expired-callback':expiredCallback,
            'theme' : theme,
            'type' : type
            });
      };
    },

    componentWillReceiveProps: function(nextProps) {
        if (nextProps && nextProps.isResetCaptcha) {
            window.grecaptcha.reset(widgetId);
        }
    },

    getScriptURL: function() {
        return 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&hl='+this.props.lang+'&render=explicit&rnd=' + Math.random();
    },

    onScriptLoaded: function() {
        this.setState({scriptLoading: false});
    },

    onScriptError: function() {
        this.setState({scriptLoading: false, scriptLoadError: true});
    },

    render: function() {
      if (this.state.scriptLoadError) {
          console.log('loading failed');
      }
      return <div className = 'g-recaptcha' id = 'captcha'></div>;
    }
});

export default Captcha