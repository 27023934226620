/* global google */
import { Currencies } from '../constants/Common-Currency'
import React from 'react';
import Moment from 'moment'
var _ = require('lodash');
var moment = require('moment');
var validator = require('validator');

export function secondsToTimeFormat(seconds=0){
    return moment.utc(seconds*1000).format('HH:mm:ss')
}

export const MultiSelectCommonFunc = {
    getSelectValues: function (e) {
        let options = e.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        return value;
    }
}

export function formatPhoneNumber(text) {
    if (Validation.isStringEmpty(text)) return ''
    return text.replace(/[a-zA-Z`~!@#$%^&*()_=\\|{};:"'></?,.-]/g, "").replace(/(?!^)\+/g, "");
}

export function formatMMYYYY(text) {
    if (Validation.isStringEmpty(text)) return ''
    if (text.length == 2) {
        if (text[1] == '/') {
            if (text[0] == '0') return '0'
            else return '0' + text[0] + ' / '
        }
    }
    if (text.length >= 9) return text.substr(0, 9)
    text = text.replace(/[a-zA-Z`~!@#$%^&*()_=\\|{};:"'></?,.-]/g, "");
    if (text.length > 0) {
        if (text.length == 1) {
            if (text == '1') return text
            if (text == '0') return text
            return '0' + text + ' / '
        } else if (text.length == 2) {
            if (parseInt(text) > 12) {
                let textArray = text.split('')
                textArray = textArray.filter(c => {
                    return c != ' '
                })
                textArray.splice(1, 0, ' / ')
                return '0' + textArray.join('')
            } else {
                return text + ' / '
            }
        }
        else {
            let textArray = text.split('')
            textArray = textArray.filter(c => {
                return c != ' '
            })
            textArray.splice(2, 0, ' / ')
            return textArray.join('')
        }
    }
    return text;
}

export function formatMMDDYYYY(text) {

    let dilime = '/'
    if (Validation.isStringEmpty(text)) return ''
    if (text.length == 2) {
        if (text[1] == '/') {
            if (text[0] == '0') return '0'
            else return '0' + text[0] + dilime
        }
    }
    if (text.length >= 10) return text.substr(0, 10)
    text = text.replace(/[a-zA-Z`~!@#$%^&*()_=\\|{};:"'></?,.-]/g, "");
    if (text.length > 0) {
        if (text.length == 1) {
            if (text == '1') return text
            if (text == '0') return text
            return '0' + text + dilime
        } else if (text.length == 2) {
            if (parseInt(text) > 12) {
                let textArray = text.split('')
                textArray = textArray.filter(c => {
                    return c != ' '
                })
                textArray.splice(1, 0, dilime)
                return '0' + textArray.join('')
            } else {
                return text
            }
        } else if (text.length == 4) {
            let mmStr = text.substring(2)
            if (parseInt(mmStr) > 31) {
                return text.substring(0, 2) + dilime + maxDay(text.substring(0, 2))
            }
            let textArray = text.split('')
            textArray.splice(2, 0, dilime)
            return textArray.join('') + dilime
        }
        else {
            let textArray = text.split('')
            textArray = textArray.filter(c => {
                return c != ' '
            })
            textArray.splice(2, 0, dilime)
            if (textArray.length >= 5)
                textArray.splice(5, 0, dilime)
            return textArray.join('')
        }
    }

    return text;
}

function maxDay(month) {
    if (month == "02") return "28"
    if (month == "04" || month == "06" || month == "09" || month == "11") return "30"
    return "31"
}

export function trimPhoneNumber(phone) {
    if (phone) {
        return phone.replace(new RegExp(' ', 'g'), '').replace(new RegExp('-', 'g'), '');
    }
    return '';
}
const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const Validation = {
    validateUsername: function (username) {
        let re = /^[a-z0-9]{3,20}$/g;
        return re.test(username.trim());
    },
    
    validateEmail: function (email,isRequire=false) {
        if((!email || email.length==0)&& !isRequire){
            return true
        }
        if(!email || email.length==0){
            return true
        }
        return validator.isEmail(email);
    },

    validateMultiEmail: function (emails) {
        let emailArray = emails.split(";");
        let validator = true;
        emailArray.forEach(function (email) {
            if (!validator.isEmail(email.trim())) {
                validator = false;
            }
        });
        return validator;
    },

    validatePassword: function (password) {
        let re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/;
        return re.test(password);
    },

    isInteger: (input) => {
        return Number.isInteger(parseFloat(input))
    },

    isGreaterThan: (input, number) => {
        return parseFloat(input) > number
    },
    isGreaterOrEqual: (input, number) => {
        if (input == '' || input === undefined || input == null) {
            input = 0;
        }
        return parseFloat(input) >= number
    },
    isInRange: (input, min,max) => {
        if (input == '' || input === undefined || input == null) {
            input = 0;
        }
        return parseFloat(input) >= min && parseFloat(input) <= max
    },
    isStringEmpty: function (input) {
        if (input === undefined) {
            return true;
        }
        if (input === null) return true;
        if (typeof input == 'string') {
            if (input.trim().length == 0) {
                return true;
            }
        }
        return false;
    },
    isNumber: function (value) {
        if (value === undefined) {
            return false;
        }
        if (value === null) {
            return false;
        }
        if (typeof value == 'string') {
            if (value.trim().length == 0) {
                return false;
            }
        }
        if (isNaN(value)) {
            return false;
        }
        return true;
    },
    isDigitOnly: function (value) {
         return /^\d+$/.test(value);
    },
    isDecimal: function(value) {
        return /^(\d*\.?\d+|\d+\.)$/.test(value);
    },
    isFormValid: function (data) {
        let isValid = true;
        Object.keys(data).every(key => {
            if (!data[key]) {
                isValid = false;
                return false;
            }
            return true;
        })
        return isValid;
    }
}
export const textboxNumberHelper= {
    onfocusHandle: (e,onChangehandle)=>{
        if(e.target.value==0 || e.target.value.trim()=='0'){
            e.target.value = '';
            if(onChangehandle){
                onChangehandle(e);
            }
        }
        
    },
    onBlurHandle:(e,onChangehandle)=>{
        if(!!!e.target.value){
            e.target.value = '0';
            if(onChangehandle){
                onChangehandle(e);
            }
        }
        
    }
}