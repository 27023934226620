import './Login.css';
import Captcha from '../../components/reCaprcha/captcha';
import { Translate, I18n } from 'react-redux-i18n';
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';
import * as loadingBarActions from './../../actions/loadingBarActions'
import { connect } from "react-redux";

import {
	Button,
	Form,
	Row,
	Col,
	FormControl,
	Checkbox,
	FormGroup,
	FormLabel,
	InputGroup,
} from 'react-bootstrap';
import { forgotPassword } from "../../actions/auth";
import Helmet from "react-helmet";

class ForgotPassword extends Component {
	constructor() {
		super();
		this.state = {
			isHuman: false,
			username: '',
			isResetCaptcha: false
		}
		this.handleForgotPassword = this.handleForgotPassword.bind(this);
		this.captchaCallback = this.captchaCallback.bind(this);
		this.captchaExpiredCallback = this.captchaExpiredCallback.bind(this);
		this.usernameChange = this.usernameChange.bind(this);
	}

	componentWillMount() {
		if (this.props.user) {
			// logged in, let's show redirect if any, or show home
			try {
				const redirect = this.props.location.query.redirect;
				this.context.router.replace(redirect);
			} catch (err) {
				this.context.router.replace("/");
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.user) {
			// logged in, let's show redirect if any, or show home
			try {
				const redirect = this.props.location.query.redirect;
				this.context.router.replace(redirect);
			} catch (err) {
				this.context.router.replace("/");
			}
		}
	}

	handleForgotPassword(event) {
		this.props.loadingBarActions.showLoadingSpiner();
		this.props.forgotPassword({ email: this.state.username })
			.then(data => {
				this.props.loadingBarActions.hideLoadingSpiner();
				if (data.ok) {
					window.location.href = '/reset-password'
				} else if (data.error) {
					this.setState({
						forgotPasswordMessage: I18n.t('errors.' + data.error.errorCode),
						forgotPasswordStyle: 'danger'
					});
				}
			});
	}
	captchaCallback(value) {
		this.setState({ isHuman: true, isResetCaptcha: false })
	}

	captchaExpiredCallback(value) {
		this.setState({ isHuman: false, isResetCaptcha: false })
	}
	usernameChange(e) {
		this.setState({ username: e.target.value });
	}
	render() {
		return (
			<div className={'login-body'}>
				<Helmet
					title={I18n.t('project.name')}
				/>
				<form className={'bg-image'}>
					<div className={'login-form mt-md-2 mt-lg-4'} >
						{this.state.forgotPasswordMessage ?
							<div className={"alert alert-" + this.state.forgotPasswordStyle}>
								<strong>{this.state.forgotPasswordMessage}</strong>
							</div> : null
						}
						<FormGroup>
							<input
								type="text"
								value={this.state.username}
								onChange={this.usernameChange}
								className="form-control"
								placeholder={I18n.t("resetPassword.Username")}
								required
								autoFocus
							/>
						</FormGroup>
						<FormGroup>
							<Captcha
								sitekey='6Lfm3yYUAAAAAHDRZA7Bz0XzUbu4N546RJCddBOQ'
								lang='en'
								theme='light'
								type='image'
								isResetCaptcha={this.state.isResetCaptcha}
								callback={this.captchaCallback}
								expiredCallback={this.captchaExpiredCallback} />

						</FormGroup>
						<Button block bsStyle='success' disabled={!this.state.isHuman || !this.state.username || this.state.username.length == 0} className='text-center login-button' onClick={this.handleForgotPassword}>Request to Reset Password</Button>
						<a className='btn btn-warning btn-block' href='/reset-password'>I have token already</a>
						<a className='btn btn-default btn-block' href='/'><Translate value='resetPassword.Login' /></a>
					</div>
				</form>
			</div>
		)
	}
}
function mapDispatchToProps(dispatch) {
	return {
		forgotPassword: (options) => { return dispatch(forgotPassword(options)) },
		loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
	}
}

export default connect(null, mapDispatchToProps)(ForgotPassword);