import './header.css';
import React, { Component } from 'react';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, FormControl, ListGroup, ListGroupItem } from 'react-bootstrap';
import { setLocale, Translate, Localize, I18n } from 'react-redux-i18n';
import { translations } from '../../constants/languages/languages'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'


class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showFleetList: false,
			fleetList: [],
			fleetListSuggestion: [],
			fleetListValue: ''
		}
		this.languageSelected = this.languageSelected.bind(this)
		this.onMyAccountClick = this.onMyAccountClick.bind(this)
	}
	componentDidMount() {

	}

	languageSelected(lang) {
		this.props.languageSelect(lang);
	}
	
	onMyAccountClick() {
		this.props.hideNewBooking();
		this.props.router.push('/profile');

	}
	render() {
		return (

			<Navbar className={'navigator navbar-fixed-top'} >
				<div className='page-title'>
					<span>CEDR: {this.props.title}</span>
				</div>
				<Nav pullRight>
					<NavDropdown eventKey={3} title={this.context.user ? <span><i className="fa fa-user-circle-o" aria-hidden="true"></i> {this.context.user.email}</span>: ''} id="basic-nav-dropdown">
						{/* <MenuItem eventKey={3.1} onClick={this.onMyAccountClick} ><Translate value='General.My_Account' /></MenuItem> */}
						<MenuItem divider />
						<MenuItem eventKey={3.2} onClick={() => { this.props.logout() }}><Translate value='General.Sign_out' /></MenuItem>
					</NavDropdown>
				</Nav>
			</Navbar>
		)
	}
}
Header.contextTypes = {
	currenLanguage: PropTypes.object,
	user: PropTypes.object
}

export default Header;