import { callApi } from "../utils/apiUtils";
import {
  ugcCommentCreateApi,
  ugcCommentsGetApi,
} from "../constants/ApiConfigs";

export function ugcCommentCreate(options) {
  let config = {
    method: "post",
    body: JSON.stringify(options),
  };
  return callApi(ugcCommentCreateApi, config);
}

export function ugcGetComments(ugcVideoId) {
  let config = {
    method: "GET",
  };
  return callApi(ugcCommentsGetApi(ugcVideoId), config);
}
