/* global google */
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
//mport 'react-select/dist/react-select.css';
import "./components/select/react-select.css";
import "./index.css";
import "./utils/extentions";
import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/app/App";
import { Provider } from "react-redux";
import { Router, Route, browserHistory, IndexRoute } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from "react-redux-i18n";
import configureStore from "./store/configureStore";
import { translations } from "./constants/languages/languages";

//All page related to Route
import NotFound from "./containers/misc/NotFound";
import Login from "./containers/login/Login";
import ForgotPassword from "./containers/login/forgotPassword";
import ChangePassword from "./containers/userSettings/changePassword";
import UserProfile from "./containers/userSettings/userProfile";
import category from "./containers/category/category";
import Video from "./containers/video/video";
import User from "./containers/user/user";
import Package from "./containers/settings/package";
import PaymentItem from "./containers/settings/paymentItem";
import Ugcvideo from "./containers/ugcvideo/ugcvideo";
import ResetPassword from "./containers/login/ResetPassword";
/////////////////////////////////////////////////////////////////////////
// browserHistory would be preferred over hashHistory, but browserHistory
// would require configuring the server. So we will use hashHistory here.
// Please change to browserHistory if you have your own backend server.
// import {browserHistory as history} from 'react-router';
// import { useRouterHistory } from "react-router";
// import { createHashHistory } from "history";
// const history = useRouterHistory(createHashHistory)();

const store = configureStore();
const history = syncHistoryWithStore(browserHistory, store);
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale(localStorage.getItem("language") || "en"));

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route path="/login" component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/" component={App}>
        <IndexRoute component={category} />
        <Route path="/category" component={category} />
        <Route path="/video" component={Video} />
        <Route path="/ugcvideo" component={Ugcvideo} />
        <Route path="/user" component={User} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/profile" component={UserProfile} />
        <Route path="/settings">
          <IndexRoute component={Package} />
          <Route path="/settings/package" component={Package} />
          <Route path="/settings/payment_item" component={PaymentItem} />
          <Route path="/settings/*" component={NotFound} />
        </Route>
      </Route>
      <Route path="/page-not-found" component={NotFound} />
      <Route path="*" component={NotFound} />
    </Router>
  </Provider>,
  document.getElementById("root")
);
