import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { setLocale, Translate, Localize } from 'react-redux-i18n';
import './sidebar.css'
import {
    Tooltip,
    OverlayTrigger
} from 'react-bootstrap';

class Sidebar extends Component {
    constructor() {
        super();
    }
    renderCollapsedIcon() {
        if (this.props.collapsed) {
            return (
                <span className={"glyphicon glyphicon-triangle-right"}></span>
            )
        } else {
            return (
                <span className={"glyphicon glyphicon-triangle-left"}></span>
            )
        }
    }
    buildClasses() {
        let sidebarClass = 'sidebar-wrapper';
        sidebarClass = this.props.collapsed ? sidebarClass + ' collapsed' : sidebarClass;
        sidebarClass = this.props.sub ? sidebarClass + ' sub-menu' : sidebarClass;
        return sidebarClass;
    }

    getMenuLink(menu, auth) {
        let link = menu.link;
        if (menu.sub && menu.sub.length > 0) {
            let mn = menu.sub.filter(m => { return m.show })[0];
            if (mn) {
                link = mn.link;
            }
        }
        if (link === '/corporate' && auth && auth.user && (auth.user.userType === 'CorporateAdmin' || auth.user.userType === 'CorporateUser')) {
            link = '/corporate/edit/' + auth.user.corporateId;
        }
        return link;
    }
    renderMenuLink() {
        const { onMenuClick, collapedClickHandle, menuList, t, addNewClick, collapsed, auth, bookingFormShow } = this.props;
        const { getMenuLink } = this;
        const delayTooltip = 500;

        if (this.props.sub) {
            return menuList.map(function (menu) {
                return menu.show ? <li key={getMenuLink(menu, auth)} className={menu.active ? 'menu-item sub active' : 'menu-item sub'}>
                    <Link to={menu.link} onClick={(e) => {e.stopPropagation(); onMenuClick(menu.key) }}>
                        <span className={'menu-text'}><Translate value={menu.text} /></span>
                    </Link>
                    {menu.sub && menu.sub.length > 0 && menu.active ?
                        <ul className='sub-level2'>
                            {menu.sub.map(mn => {
                                return mn.show ? <li key={getMenuLink(mn, auth)} className={mn.active ? 'menu-item sub level2 active' : 'menu-item sub level2'}>
                                    <Link to={getMenuLink(mn, auth)} onClick={(e) => { e.stopPropagation(); onMenuClick(mn.key) }}>
                                        <i className="fa fa-caret-right" aria-hidden="true"></i>
                                        <span className={'menu-text'}><Translate value={mn.text} /></span>
                                    </Link>
                                </li> : ''
                            })}
                        </ul> : null
                    }
                </li> : '';
            });
        } else {
            return menuList.map(function (menu) {
                    return menu.show ?
                        <li key={getMenuLink(menu, auth)} className={menu.active && !bookingFormShow ? 'menu-item active' : 'menu-item'} >
                            {
                                collapsed ? <OverlayTrigger placement="right" delayShow={delayTooltip} overlay={(<Tooltip id="tooltip"><strong><Translate value={menu.text} /></strong></Tooltip>)}>
                                    <Link to={getMenuLink(menu, auth)} style={{
                                        display: 'flex',
                                    }} onClick={(e) => {e.stopPropagation(); onMenuClick(menu.key)}}>
                                        <div
                                            style={{
                                                backgroundImage: menu.icon, backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'auto 24px', backgroundPosition: 'center',
                                                height: 40, width: 30,// float: 'left'

                                            }}>
                                        </div>
                                        <div className={'menu-text'} style={{ flex: 1, alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 5 }}>
                                            <Translate value={menu.text} />
                                        </div>
                                        {
                                            menu.sub && menu.sub.length > 0 && !collapsed ?
                                                <div className='sub-arrow'>
                                                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                                                </div> : null
                                        }
                                    </Link>
                                </OverlayTrigger> :
                                    <Link to={getMenuLink(menu, auth)} style={{
                                        display: 'flex',
                                    }} onClick={(e) => {e.stopPropagation(); onMenuClick(menu.key)}}>
                                        <div
                                            style={{
                                                backgroundImage: menu.icon, backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'auto 24px', backgroundPosition: 'center',
                                                height: 40, width: 30,// float: 'left'
                                            }}>
                                        </div>
                                        <div className={'menu-text'} style={{ /*float: 'left', display: 'inline-block'*/flex: 1, alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 5 }}>
                                            <Translate value={menu.text} />
                                        </div>
                                        {
                                            menu.sub && menu.sub.length > 0 && !collapsed ?
                                                <div className='sub-arrow'>
                                                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                                                </div> : null
                                        }
                                    </Link>
                            }
                        </li> : null

            });
        }
    }
    render() {
        const collapsedIcon = <li className="sidebar-brand">
            <a onClick={this.props.collapedClickHandle}>
                {this.props.collapsed ? (
                    <span className={"glyphicon glyphicon-chevron-right"}></span>
                ) : (
                        <span className={"glyphicon glyphicon-chevron-left"}></span>
                    )}
            </a>
        </li>
        const menuListRendered = this.renderMenuLink();
        return (
            <div className={this.buildClasses()}>
                <ul className="sidebar-nav">
                    {!this.props.sub ? collapsedIcon : ''}
                    {menuListRendered}
                </ul>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);