import _ from "lodash";
export const en = {
  name: "English",
  flagCode: "us",
  locale: "en-en",
  project: {
    name: "CEDR Sivoo ",
  },
  Sidebar: {
    Category: "Categories",
    Video: "Videos",
    UGCVideo: "UGCVideos",
    User: "Users",
    Settings: "Settings",
    settings: {
      Package: "Packages",
      Payment_Item: "Payment Items",
    },
  },
  table_header: {
    Items_per_page: "Items per page",
    Page: "Page",
    Search_here: "Search here",
    Shown_Active: "{0} - {1} of {2} shown ",
    Active: "{0} Active ",
  },
  messages: {
    commonMessages: {
      Required_field: "This field is required!",
      item_no_found: "No items found!",
      Email_invalid: "Email invalid format!",
      greater_or_equa: "Please enter a value greater than or equal to {0}.",
    },
  },
  errors: {},
  General: {
    Sign_out: "Sign out",
  },
  login: {
    Username: "Username/Email",
    Password: "Password",
    Remember_me: "Remember me",
    Forgot_your_password: "Forgot your password",
    Login: "Login",
  },
  resetPassword: {
    INFO_SUCCESS: "INFO_SUCCESS",
    Username: "Username/email",
    Send_New_Password: "Send New Password",
    Login: "Login",
  },
  category: {
    Update_category_fail: "Category have been updated fail!",
    Update_category_success: "Category have been updated successfully",
    Create_category_fail: "Category have been created fail",
    Create_category_success: "Category have been created successfully",
    Load_category_fail: "Category have been loaded fail",
    Delete_category_success: "Category have been deleted successfully",
    Delete_category_fail: "Category have been deleted fail",
    Actions: "Actions",
    Edit: "Edit",
    Delete: "Delete",
    DELETE_CATEGORY: "DELETE CATEGORY",
    DELETE_CONFIRM: "CONFIRM DELETE CATEGORY",
    EDIT_CATEGORY: "EDIT CATEGORY",
    DETAIL_CATEGORY: "DETAIL CATEGORY",
    Yes: "Yes",
    Name: "Name",
    Save: "Save",
    Cancel: "Cancel",
    Add: "Add",
  },
  video: {
    Edit: "Edit",
    Delete: "Delete",
    DELETE_VIDEO: "DELETE VIDEO!",
    DELETE_CONFIRM: "CONFIRM DELETE VIDEO!",
    Yes: "Yes",
    EDIT_VIDEO: "EDIT VIDEO",
    DETAIL_VIDEO: "DETAIL VIDEO",
    ADD_VIDEO: "ADD VIDEO",
    Upload_an_image: "Upload video's poster",
    Title: "Title",
    Video_HLS_URL: "HLS Url",
    Category: "Category",
    Select_category: "Select category",
    Duration: "Duration",
    Description: "Video's description",
    Save: "Save",
    Cancel: "Cancel",
    Add: "Add",
    UgcVideo_Status: "Status",
    Thumbnail: "Thumbnail",
    Created_date: "Created time",
    Actions: "Actions",
    Key_words: "Key words",
    Delete_video_success: "video have been deleted successfully",
    Delete_video_fail: "Video have been delete fail!",
    Load_video_fail: "video have been loaded fail!",
    Create_video_success: "Video created successfully",
    Create_video_fail: "Video have been created fail!",
    Update_video_success: "Video have been updated successfully",
    Update_video_fail: "Video have been updated fail",
    Video_type: "Video type",
    Free_Video: "Free video",
    Subscribled_Video: "Subscribled Video",
    Pricing_Video: "Pricing video",
    Video_Price: "Video' price",
    Free_Select_price: "Please select price",
    Set_Video_On_Top: "Set video on top",
    Is_Live_Video: "Is Live video",
  },
  user: {
    Update_user_fail: "",
    Update_video_success: "",
    Update_user_success: "",
    Create_user_fail: "",
    Create_user_success: "",
    Load_user_fail: "",
    Delete_user_success: "",
    Delete_user_fail: "",
    Actions: "Actions",
    Edit: "Edit",
    Delete: "Delete",
    DELETE_USER: "DELETE USER",
    DELETE_CONFIRM: "DELETE CONFIRM",
    Yes: "Yes",
    Cancel: "Cancel",
    EDIT_USER: "EDIT USER",
    DETAIL_USER: "DETAIL USER",
    Upload_an_avatar: "Upload_an_avatar",
    Email: "Email",
    Gender: "Gender",
    Male: "Male",
    Female: "Female",
    User_type: "User type",
    user: "User",
    admin: "admin",
    Save: "Save",
    Key_words: "Key words",
    level0: "Free",
    level1: "Subscribled",
    level2: "VIP",
    User_Level: "Level",
  },
  package: {
    Package_name: "Package name",
    package_ID: "Package Id",
    Price: "Price",
    Durration: "Durration",
    Actions: "Actions",
    Package_Type: "Package Type",
    Please_Select_price: "Please select price",
  },
  paymentItem: {
    Actions: "Actions",
    Edit: "Edit",
    Delete: "Delete",
    item_no_found: "item not found",
    DELETE_PAYMENT_ITEM: "DELETE PAYMENT ITEM",
    DELETE_CONFIRM: "Are you sure to delete this payment Item?",
    Yes: "Yes",
    EDIT_PAYMENT_ITEM: "EDIT PAYMENT ITEM",
    Name: "Name",
    Console_Id: "Console Id",
    Price: "Price",
    Required_fields: "This field is required!",
    Save: "Save",
    Cancel: "Cancel",
    Add: "Add",
  },
  ugcVideoAction: {
    admin_approve: "APPROVE",
    admin_reject: "REJECT",
    admin_delete: "DELETE",
  },
};
