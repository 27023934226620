import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Translate, I18n } from 'react-redux-i18n';
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    ControlLabel,
    InputGroup,
    Button, Radio,
    Glyphicon,
    ButtonToolbar,
    DropdownButton,
    MenuItem,
    Modal,
    ListGroup,
    ListGroupItem,
    Checkbox,
    Tab,
    Tabs,
    Alert,
    Pagination
} from 'react-bootstrap';
import Datetime from 'react-datetime';
import * as packageActions from '../../actions/packageActions'
import * as paymentItemActions from '../../actions/paymentItemActions'
import * as loadingBarActions from './../../actions/loadingBarActions'
import { bindActionCreators } from 'redux';
import { Validator, ValidCase } from '../../components/validator'
import {Validation } from '../../utils/commonFunctions'
import TableHeader from '../../components/table/TableHeader';
import defaultBioAvatar from '../../constants/staticFiles/default_bio.jpg'
var moment = require('moment');
var timeoutID = null;
class Package extends Component {
    constructor() {
        super();
        this.state = {
            packages: null,
            activePage: 1,
            numItemsPerPage: 20,
            detailItem: null,
            showDialog: false,
            dialogData: null,
            dialogChanged: false,
            showConfirm: false,
            sortType: -1,
            valid: {},
            isSubmitted: false,
            editable: false,
            filter:{}
        }
        this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
        this.handlePaginationChose = this.handlePaginationChose.bind(this);
        this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.updateUsersList = this.updateUsersList.bind(this);
        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.closeDialogForm = this.closeDialogForm.bind(this);
        this.saveDialogForm = this.saveDialogForm.bind(this);
        this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
        this.ValidatorCallback = this.ValidatorCallback.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }
    componentDidMount() {
        this.updateUsersList();
        this.getpaymentItemsList();
    }

    updateUsersList(activePage,query={}) {
        activePage = activePage || 1;
        this.props.packageActions.packageGetList().then(data => {
            if (data.ok && data.res) {
                this.setState({ packages: data.res })
            }
        });
    }
    getpaymentItemsList() {
        this.props.paymentItemActions.paymentItemGetList().then(data => {
            if (data.ok && data.res) {
                this.state.paymentItems = data.res.list;
            }
        });
    }

    saveDialogForm() {
        if (!this.state.isSubmited) {
            this.setState({ isSubmited: true })
        }
        if (!Validation.isFormValid(this.state.valid)) {
            return;
        }
        this.props.loadingBarActions.showLoadingSpiner()
        let objectUser = {
            "name": this.state.dialogData.name,
            "paymentItemId": this.state.dialogData.paymentItemId,
            "duration": this.state.dialogData.duration,
            "type": this.state.dialogData.type
           }
        if (this.state.dialogData._id) {
            objectUser.id = this.state.dialogData._id;
            this.props.packageActions.packageUpdate(objectUser).then(data => {
                this.props.loadingBarActions.hideLoadingSpiner()
                if (!data.ok) {
                    if (data.error) {
                        this.context.notification('error', I18n.t('errors.' + data.error.errorCode));
                    } else {
                        this.context.notification('error', I18n.t('package.Update_package_fail'))
                    }
                } else {
                    this.context.notification('success', I18n.t('package.Update_package_success'))
                    this.closeDialogForm();
                    this.updateUsersList(this.state.activePage,this.state.filter);
                    this.setState({ isSubmited: false })
                }
            });
        } else {
            objectUser.email = this.state.dialogData.email;
            this.props.packageActions.packageCreate(objectUser).then(data => {
                this.props.loadingBarActions.hideLoadingSpiner()
                if (!data.ok) {
                    if (data.error) {
                        this.context.notification('error', I18n.t('errors.' + data.error.errorCode));
                    } else {
                        this.context.notification('error', I18n.t('package.Create_package_fail'))
                    }
                } else {
                    this.context.notification('success', I18n.t('package.Create_package_success'))
                    this.closeDialogForm();
                    this.updateUsersList(this.state.activePage,this.state.filter);
                    this.setState({ isSubmited: false })
                }
            });
        }
    }
    handleInputChange(key,e) {
        this.state.dialogData[key] = e.target.value;
        this.setState({ dialogData: this.state.dialogData, dialogChanged: true })
    }
    handleAddButtonClick() {
        let objectUser = {
            "name": '',
            "paymentItemId": '',
            "duration": 1,
            "type": 1,
        }
        this.setState({ showDialog: true, dialogData: objectUser, editable: true });
    }
    closeDialogForm() {
        this.setState({ showDialog: false, dialogData: null, dialogChanged: false, showConfirm: false, editable: false });
    }
    handleMenuClick(key, pk) {
        switch (key) {
            case 'Edit': {
                this.props.packageActions.packageGetDetail({_id: pk._id}).then(data=>{
                    if(data.ok && data.res){
                        this.setState({ showDialog: true, dialogData: data.res, dialogChanged: false, editable: true  });
                    }else{
                        this.context.notification('error', I18n.t('package.Load_package_fail'))
                    }
                })
                break;
            }
            case 'View': {
                this.props.packageActions.packageGetDetail({_id: pk._id}).then(data=>{
                    if(data.ok && data.res){
                        this.setState({ showDialog: true, dialogData: data.res, dialogChanged: false, editable: false  });
                    }else{
                        this.context.notification('error', I18n.t('package.Load_package_fail'))
                    }
                })
                break;
            }
            case 'changeStatus': {
                this.setState({ editable: true })
                break;
            }
            case 'Delete': {
                this.setState({ showConfirm: true, dialogData: pk });
                break;
            }
        }
    }

    handlePaginationSelect(eventKey) {
        this.updateUsersList(eventKey,this.state.filter);
    }

    handlePaginationChose(e) {
        this.updateUsersList(parseInt(e.target.value),this.state.filter);
    }

    handleNumItemsPerPageChange(e) {
        let numItemsPerPage = parseInt(e.target.value);
        let numPage = 1
        this.setState({
            numItemsPerPage: numItemsPerPage,
        }, function () {
            this.updateUsersList(numPage,this.state.filter);
        });
    }

    confirmDeleteUser() {
        this.props.packageActions.deleteUser({
            fleetId: this.props.auth.selectedFleet.fleetId,
            packageId: this.state.dialogData._id
        }).then(data => {
            if (data.ok) {
                this.closeDialogForm();
                this.updateUsersList(this.state.activePage,this.state.filter);
                this.context.notification('success', I18n.t('package.Delete_package_success'));
            } else {
                if (data.error) {
                    this.context.notification('error', I18n.t('errors.' + data.error.errorCode));
                } else {
                    this.context.notification('error', I18n.t('package.Delete_package_fail'))
                }
            }
        })
    }
    ValidatorCallback(id, valid, messages) {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    }
    
    renderDataItemList() {
        const { handleMenuClick, changeStatus } = this;
        if (this.state.packages && this.state.packages.list.length > 0) {
            return this.state.packages.list.map(pk => {
                return <tr key={pk._id}>
                    <td>
                        {
                            <a className="clickable" onClick={() => { handleMenuClick('View', pk) }}>
                                {pk.name}
                            </a>
                        }
                    </td>
                    <td>
                        {pk.price}USD
                    </td>
                    <td>{pk.duration} Month(s)</td>
                    <td>
                        {pk.type==1? I18n.t('package.Subscribled'): I18n.t('package.VIP')}
                    </td>
                    <td>
                        <DropdownButton title={<Translate value='package.Actions' />} pullRight id="bg-nested-dropdown" onSelect={(eventKey) => { handleMenuClick(eventKey, pk) }}>
                            <MenuItem eventKey="Edit"><Translate value='package.Edit' /></MenuItem>
                            <MenuItem eventKey="Delete"><Translate value='package.Delete' /></MenuItem>
                        </DropdownButton>
                    </td>
                </tr>
            })
        } else {
            return <tr><td className='item-no-found' colSpan={5}><Translate value='messages.commonMessages.item_no_found' /></td></tr>
        }

    }
    render() {
        return (
            <div className='content'>
                <Modal show={this.state.showConfirm} backdrop={true} onHide={this.closeDialogForm}>
                    <Modal.Header closeButton>
                        <Modal.Title><Translate value='package.DELETE_PACKAGE' /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Translate value='package.DELETE_CONFIRM' />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle='success' onClick={this.confirmDeleteUser} ><Translate value='package.Yes' /></Button>
                        <Button bsStyle="warning" onClick={this.closeDialogForm}><Translate value='package.Cancel' /></Button>
                    </Modal.Footer>
                </Modal>
                {
                    this.state.dialogData
                        && <Modal show={this.state.showDialog} backdrop={true} onHide={this.closeDialogForm}>
                            <Modal.Header closeButton>
                                <Modal.Title>{this.state.dialogData._id ?  this.state.editable ? <Translate value='package.EDIT_PACKAGE' /> : <Translate value='package.DETAIL_PACKAGE' className='upper' /> : <Translate value='package.ADD_PACKAGE' />}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup validationState={!this.state.isSubmited ? null : (this.state.valid.name ? null : 'error')}>
                                    <ControlLabel><Translate value='package.Name' /><span className='require'>*</span></ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={(e)=>{this.handleInputChange('name',e)}}
                                        value={this.state.dialogData ? this.state.dialogData.name : ''}
                                        disabled={!this.state.editable}
                                        placeholder={I18n.t("package.Name")}
                                    />
                                    <Validator id='name' callback={this.ValidatorCallback}>
                                        <ValidCase hide={!this.state.isSubmited} valid={!Validation.isStringEmpty(this.state.dialogData.name)} message={I18n.t('messages.commonMessages.Required_field')} />
                                    </Validator>
                                </FormGroup>
                                <FormGroup >
                                    <ControlLabel><Translate value='package.Durration' /><span className='require'>*</span></ControlLabel>
                                    <FormControl disabled={!this.state.editable} 
                                                componentClass='select' 
                                                value={this.state.dialogData ? this.state.dialogData.duration:''}
                                                onChange={(e)=>{this.handleInputChange('duration',e)}}>
                                                    <option value='1'>1 Month</option>  
                                                    <option value='3'>3 Months</option>       
                                                    <option value='6'>6 Months</option>    
                                                    <option value='12'>12 Months</option>                                 
                                    </FormControl>
                                </FormGroup>
                                <FormGroup >
                                    <ControlLabel><Translate value='package.Payment_price' /><span className='require'>*</span></ControlLabel>
                                    <FormControl disabled={!this.state.editable} 
                                                componentClass='select' 
                                                value={this.state.dialogData ? this.state.dialogData.paymentItemId:''}
                                                onChange={(e)=>{this.handleInputChange('paymentItemId',e)}}>
                                                <option value=''>{I18n.t('package.Please_Select_price')}</option>   
                                                {
                                                    this.state.paymentItems && this.state.paymentItems.map(pm=><option value={pm._id}>${pm.price}({pm.name})</option>)
                                                }                                        
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel><Translate value='package.Package_type' /><span className='require'>*</span></ControlLabel>
                                    <FormControl disabled={!this.state.editable} 
                                                componentClass='select' 
                                                value={this.state.dialogData ? this.state.dialogData.type:''}
                                                onChange={(e)=>{this.handleInputChange('type',e)}}>
                                                    <option value="1">{I18n.t('package.Subscribled')}</option>     
                                                    <option value="2">{I18n.t('package.VIP')}</option>                                          
                                    </FormControl>
                                </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <ControlLabel>(<span className='require'>*</span>): <Translate value='package.Required_fields' /></ControlLabel>
                                {!this.props.permissions || this.props.permissions.actions ? this.state.editable ?
                                    <Button bsStyle='success' onClick={this.saveDialogForm}
                                        disabled={!Validation.isFormValid(this.state.valid)}><Translate value='package.Save' /></Button> 
                                        : <Button bsStyle='success' onClick={(e) =>{this.handleMenuClick("changeStatus", e)}}>
                                        <Translate value='package.Edit' /></Button>
                                    : null
                                }
                                <Button bsStyle="warning" onClick={this.closeDialogForm}><Translate value='package.Cancel' /></Button>
                            </Modal.Footer>
                        </Modal>
                }
                <div className='row-fluid clearfix table-header-controls-container'>
                    {
                        !this.props.permissions || this.props.permissions.actions ?
                        <FormGroup className='table-header-control'>
                            <ButtonToolbar className='text-center header-button-group'>
                                <Button bsSize='lg' bsStyle='success' onClick={this.handleAddButtonClick}> <Glyphicon glyph='plus' /><Translate value='package.Add' /></Button>
                            </ButtonToolbar>    
                        </FormGroup> : ''
                    }
                </div>
                <TableHeader
                    data={this.state.packages} isHeader={true}
                    name={''}
                    handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                    handlePaginationSelect={this.handlePaginationSelect}
                />
                <table className="table table-striped table-hover">
                    <thead>
                        <tr className=''>
                            <th><Translate value='package.Package_name' /></th>
                            <th><Translate value='package.Price' /></th>
                            <th><Translate value='package.Durration' /></th>
                            <th><Translate value='package.Package_Type' /></th>
                            <th><Translate value='package.Actions' /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderDataItemList()}
                    </tbody>
                </table>
                <TableHeader
                    data={this.state.packages} isHeader={false}
                    name={''}
                    handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                    handlePaginationSelect={this.handlePaginationSelect}
                />
            </div>
        )
    }
}

Package.contextTypes = {
    notification: PropTypes.func,
}

function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        socket: state.socket,
        packages: state.packages,
        permissions: state.menuHandle.modulePermission
    }
}

function mapDispatchToProps(dispatch) {
    return {
        packageActions: bindActionCreators(packageActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
        paymentItemActions: bindActionCreators(paymentItemActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Package);