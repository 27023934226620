import {callApi,callApiBase,loadUserProfile, UrlBuilder} from '../utils/apiUtils'
import { userCreateApi,
        userGetListApi,
        userGetDetailApi,
        userDeleteApi,
        userUpdateApi} from '../constants/ApiConfigs'

export function userGetDetail(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        userGetDetailApi,
        config
    )
}

export function userGetList(options){
    let config = {
        method: 'post',
        body: JSON.stringify(options)
    }
    return callApi(
        userGetListApi,
        config
    )
}

export function userCreate(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        userCreateApi,
        config
    )
}
export function userUpdate(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        userUpdateApi,
        config
    )
}
export function userdelete(options){
    let config = {
        method: 'delete',
        body:JSON.stringify(options)
    }
    return callApi(
        userUpdateApi,
        config
    )
}