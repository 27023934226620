import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Translate, I18n } from 'react-redux-i18n';
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    ControlLabel,
    InputGroup,
    Button, Radio,
    Glyphicon,
    ButtonToolbar,
    DropdownButton,
    MenuItem,
    Modal,
    ListGroup,
    ListGroupItem,
    Checkbox,
    Tab,
    Tabs,
    Alert,
    Pagination
} from 'react-bootstrap';
import Datetime from 'react-datetime';
import * as userActions from '../../actions/userActions'
import * as loadingBarActions from './../../actions/loadingBarActions'
import { bindActionCreators } from 'redux';
import { Validator, ValidCase } from '../../components/validator'
import {Validation } from '../../utils/commonFunctions'
import TableHeader from '../../components/table/TableHeader';
import defaultBioAvatar from '../../constants/staticFiles/default_bio.jpg'
var moment = require('moment');
var timeoutID = null;
class User extends Component {
    constructor() {
        super();
        this.state = {
            users: null,
            activePage: 1,
            numItemsPerPage: 20,
            detailItem: null,
            showDialog: false,
            dialogData: null,
            dialogChanged: false,
            showConfirm: false,
            sortType: -1,
            valid: {},
            isSubmitted: false,
            editable: false,
            filter:{}
        }
        this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
        this.handlePaginationChose = this.handlePaginationChose.bind(this);
        this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.updateUsersList = this.updateUsersList.bind(this);
        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.closeDialogForm = this.closeDialogForm.bind(this);
        this.saveDialogForm = this.saveDialogForm.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
        this.handleAvatarImageChange = this.handleAvatarImageChange.bind(this);
        this.removeAvatarImageHandle = this.removeAvatarImageHandle.bind(this);
        this.ValidatorCallback = this.ValidatorCallback.bind(this)
        this.handleFilter = this.handleFilter.bind(this);
        this.handleDateOfBirthChanged = this.handleDateOfBirthChanged.bind(this)
        this.handleGenerChanged = this.handleGenerChanged.bind(this);
        this.handleUserTypeChanged = this.handleUserTypeChanged.bind(this);
        this.handleUserlevelChanged = this.handleUserlevelChanged.bind(this)
        this.handlePackageExpiredChanged = this.handlePackageExpiredChanged.bind(this)
    }
    componentDidMount() {
        this.updateUsersList();
    }

    updateUsersList(activePage,query={}) {
        activePage = activePage || 1;
        this.props.userActions.userGetList(
            {
                "query":query,
                "page": activePage-1,
                "limit": this.state.numItemsPerPage,
                "sort": {
                    "createdDate": -1
                }
            }
        ).then(data => {
            if (data.ok && data.res) {
                this.setState({ users: data.res })
            }
        });
    }

    saveDialogForm() {
        if (!this.state.isSubmited) {
            this.setState({ isSubmited: true })
        }
        if (!Validation.isFormValid(this.state.valid)) {
            return;
        }
        this.props.loadingBarActions.showLoadingSpiner()
        let objectUser = {
            "name": this.state.dialogData.name,
            "gender": parseInt(this.state.dialogData.gender),
            "dOB": this.state.dialogData.dOB? moment(this.state.dialogData.dOB).format('YYYY-MM-DD'): '',
            "phone": this.state.dialogData.phone,
            "userType":this.state.dialogData.userType,
            "level":this.state.dialogData.level,
            "expirePackage": this.state.dialogData.expirePackage? moment(this.state.dialogData.expirePackage).format('YYYY-MM-DD'):'',
           }
        if (this.state.dialogData._id) {
            objectUser._id = this.state.dialogData._id;
            this.props.userActions.userUpdate(objectUser).then(data => {
                this.props.loadingBarActions.hideLoadingSpiner()
                if (!data.ok) {
                    if (data.error) {
                        this.context.notification('error', I18n.t('errors.' + data.error.errorCode));
                    } else {
                        this.context.notification('error', I18n.t('user.Update_user_fail'))
                    }
                } else {
                    this.context.notification('success', I18n.t('user.Update_user_success'))
                    this.closeDialogForm();
                    this.updateUsersList(this.state.activePage,this.state.filter);
                    this.setState({ isSubmited: false })
                }
            });
        } else {
            objectUser.email = this.state.dialogData.email;
            this.props.userActions.userCreate(objectUser).then(data => {
                this.props.loadingBarActions.hideLoadingSpiner()
                if (!data.ok) {
                    if (data.error) {
                        this.context.notification('error', I18n.t('errors.' + data.error.errorCode));
                    } else {
                        this.context.notification('error', I18n.t('user.Create_user_fail'))
                    }
                } else {
                    this.context.notification('success', I18n.t('user.Create_user_success'))
                    this.closeDialogForm();
                    this.updateUsersList(this.state.activePage,this.state.filter);
                    this.setState({ isSubmited: false })
                }
            });
        }
    }
    handleNameChange(e) {
        this.state.dialogData.name = e.target.value;
        this.setState({ dialogData: this.state.dialogData, dialogChanged: true })
    }

    handleDateOfBirthChanged(e){
        this.state.dialogData.dOB = e._d
        this.setState({dialogData:this.state.dialogData})
    }

    handleGenerChanged(e){
        this.state.dialogData.gender = e.target.value
        this.setState({dialogData:this.state.dialogData})
    }

    handleUserTypeChanged(e){
        this.state.dialogData.userType = e.target.value
        this.setState({dialogData:this.state.dialogData})
    }
    handleUserlevelChanged(e){
        this.state.dialogData.level = e.target.value
        if(e.target.value==0){
            delete this.state.dialogData.expirePackage
        }
        this.setState({dialogData:this.state.dialogData})
    }
    handlePackageExpiredChanged(e){
        this.state.dialogData.expirePackage = e._d
        this.setState({dialogData:this.state.dialogData})
    }
    handleAddButtonClick() {
        let objectUser = {
            "avatarBase64": null,
            "name": "",
            "gender": 1,
            "dOB": new Date(),
            "phone": "",
            level: 0,
        }
        this.setState({ showDialog: true, dialogData: objectUser, editable: true });
    }
    closeDialogForm() {
        this.setState({ showDialog: false, dialogData: null, dialogChanged: false, showConfirm: false, editable: false });
    }
    handleMenuClick(key, user) {
        switch (key) {
            case 'Edit': {
                this.props.userActions.userGetDetail({_id: user._id}).then(data=>{
                    if(data.ok && data.res){
                        this.setState({ showDialog: true, dialogData: data.res, dialogChanged: false, editable: true  });
                    }else{
                        this.context.notification('error', I18n.t('user.Load_user_fail'))
                    }
                })
                break;
            }
            case 'View': {
                this.props.userActions.userGetDetail({_id: user._id}).then(data=>{
                    if(data.ok && data.res){
                        this.setState({ showDialog: true, dialogData: data.res, dialogChanged: false, editable: false  });
                    }else{
                        this.context.notification('error', I18n.t('user.Load_user_fail'))
                    }
                })
                break;
            }
            case 'changeStatus': {
                this.setState({ editable: true })
                break;
            }
            case 'Delete': {
                this.setState({ showConfirm: true, dialogData: user });
                break;
            }
        }
    }

    handlePaginationSelect(eventKey) {
        this.updateUsersList(eventKey,this.state.filter);
    }

    handlePaginationChose(e) {
        this.updateUsersList(parseInt(e.target.value),this.state.filter);
    }

    handleNumItemsPerPageChange(e) {
        let numItemsPerPage = parseInt(e.target.value);
        let numPage = 1
        this.setState({
            numItemsPerPage: numItemsPerPage,
        }, function () {
            this.updateUsersList(numPage,this.state.filter);
        });
    }

    confirmDeleteUser() {
        this.props.userActions.deleteUser({
            fleetId: this.props.auth.selectedFleet.fleetId,
            userId: this.state.dialogData._id
        }).then(data => {
            if (data.ok) {
                this.closeDialogForm();
                this.updateUsersList(this.state.activePage,this.state.filter);
                this.context.notification('success', I18n.t('user.Delete_user_success'));
            } else {
                if (data.error) {
                    this.context.notification('error', I18n.t('errors.' + data.error.errorCode));
                } else {
                    this.context.notification('error', I18n.t('user.Delete_user_fail'))
                }
            }
        })
    }
    removeAvatarImageHandle() {
        this.state.dialogData.avatar = null;
        this.state.dialogData.avatarBase64  = null;
        this.state.dialogData.avatarFile = null
        this.setState({ carModel: this.state.dialogData });
    }
    handleAvatarImageChange(e) {
        e.preventDefault();
        this.state.dialogData.avatarBase64  = null;
        this.state.dialogData.avatarFile = null
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.state.dialogData.avatarBase64  = reader.result;
            this.state.dialogData.avatarFile = file;
            this.setState({ carModel: this.state.dialogData });
        }
        reader.readAsDataURL(file);
    }

    ValidatorCallback(id, valid, messages) {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    }
    handleFilter(type,e){
        this.state.filter[type] = e.target.value;
        if(timeoutID){
            clearTimeout(timeoutID);
        }
        timeoutID = setTimeout(()=>{
            this.updateUsersList(this.state.activePage,this.state.filter)
        },300)
        this.setState({filter:this.state.filter});
    }
    
    renderDataItemList() {
        const { handleMenuClick, changeStatus } = this;
        if (this.state.users && this.state.users.list.length > 0) {
            return this.state.users.list.map(user => {
                return <tr key={user._id}>
                    <td>
                        {
                            <a className="clickable" onClick={() => { handleMenuClick('View', user) }}>
                                {user.email}
                            </a>
                        }
                    </td>
                    <td>
                        {user.name}
                    </td>
                    <td>{user.gender==0? 'Male' : 'Female'}</td>
                    <td>
                        {user.userType}
                    </td>
                    <td>
                        {I18n.t('user.level'+user.level)}
                    </td>
                    <td>
                        <DropdownButton title={<Translate value='user.Actions' />} pullRight id="bg-nested-dropdown" onSelect={(eventKey) => { handleMenuClick(eventKey, user) }}>
                            <MenuItem eventKey="Edit"><Translate value='user.Edit' /></MenuItem>
                            <MenuItem eventKey="Delete"><Translate value='user.Delete' /></MenuItem>
                        </DropdownButton>
                    </td>
                </tr>
            })
        } else {
            return <tr><td className='item-no-found' colSpan={5}><Translate value='messages.commonMessages.item_no_found' /></td></tr>
        }

    }
    render() {
        return (
            <div className='content'>
                <Modal show={this.state.showConfirm} backdrop={true} onHide={this.closeDialogForm}>
                    <Modal.Header closeButton>
                        <Modal.Title><Translate value='user.DELETE_USER' /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Translate value='user.DELETE_CONFIRM' />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle='success' onClick={this.confirmDeleteUser} ><Translate value='user.Yes' /></Button>
                        <Button bsStyle="warning" onClick={this.closeDialogForm}><Translate value='user.Cancel' /></Button>
                    </Modal.Footer>
                </Modal>
                {
                    this.state.dialogData
                        && <Modal show={this.state.showDialog} backdrop={true} onHide={this.closeDialogForm}>
                            <Modal.Header closeButton>
                                <Modal.Title>{this.state.dialogData._id ?  this.state.editable ? <Translate value='user.EDIT_USER' /> : <Translate value='user.DETAIL_USER' className='upper' /> : <Translate value='user.ADD_USER' />}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup>
                                    <div className='image-container'>
                                        <img className='user-avatar' src={this.state.dialogData.avatar || defaultBioAvatar } />                                           
                                    </div>
                                </FormGroup>
                                <FormGroup validationState={!this.state.isSubmited ? null : (this.state.valid.email ? null : 'error')}>
                                    <ControlLabel><Translate value='user.Email' /><span className='require'>*</span></ControlLabel>
                                    <FormControl
                                        type="text"
                                        value={this.state.dialogData ? this.state.dialogData.email : ''}
                                        disabled={this.state.dialogData._id? true : false}
                                        placeholder={I18n.t("user.Email")}
                                    />
                                    <Validator id='email' callback={this.ValidatorCallback}>
                                        <ValidCase hide={!this.state.isSubmited} valid={!Validation.isStringEmpty(this.state.dialogData.email)} message={I18n.t('messages.commonMessages.Required_field')} />
                                        <ValidCase hide={!this.state.isSubmited} valid={Validation.validateEmail(this.state.dialogData.email)} message={I18n.t('messages.commonMessages.Email_invalid')} />
                                    </Validator>
                                </FormGroup>
                                <FormGroup validationState={!this.state.isSubmited ? null : (this.state.valid.name ? null : 'error')}>
                                    <ControlLabel><Translate value='user.Name' /><span className='require'>*</span></ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={this.handleNameChange}
                                        value={this.state.dialogData ? this.state.dialogData.name : ''}
                                        disabled={!this.state.editable}
                                        placeholder={I18n.t("user.Name")}
                                    />
                                    <Validator id='name' callback={this.ValidatorCallback}>
                                        <ValidCase hide={!this.state.isSubmited} valid={!Validation.isStringEmpty(this.state.dialogData.name)} message={I18n.t('messages.commonMessages.Required_field')} />
                                    </Validator>
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel><Translate value='user.Date_of_birth' /></ControlLabel>
                                    <FormGroup>
                                        <Datetime inputProps={{
                                            readOnly: true, 
                                            disabled: !this.state.editable, 
                                            className: !!this.state.editable ? 'date-readonly form-control' : 'form-control'
                                        }}
                                            value={this.state.dialogData.dOB} 
                                            timeFormat={false}
                                            onChange={this.handleDateOfBirthChanged} />
                                    </FormGroup>
                                </FormGroup>
                                <FormGroup >
                                    <ControlLabel><Translate value='user.Gender' /><span className='require'>*</span></ControlLabel>
                                    <FormControl disabled={!this.state.editable} 
                                                componentClass='select' 
                                        value={this.state.dialogData ? this.state.dialogData.gender:''}
                                                onChange={this.handleGenerChanged}>
                                        <option value="0">{I18n.t('user.Male')}</option>    
                                        <option value="1">{I18n.t('user.Female')}</option>                                          
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel><Translate value='user.User_type' /><span className='require'>*</span></ControlLabel>
                                    <FormControl disabled={!this.state.editable} 
                                                componentClass='select' 
                                        value={this.state.dialogData ? this.state.dialogData.categoryId:''}
                                                onChange={this.handleUserTypeChanged}>
                                        <option value="user">{I18n.t('user.user')}</option>     
                                        <option value="admin">{I18n.t('user.admin')}</option>                                          
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel><Translate value='user.Subscribled_level' /><span className='require'>*</span></ControlLabel>
                                    <FormControl disabled={!this.state.editable} 
                                                componentClass='select' 
                                        value={this.state.dialogData ? this.state.dialogData.level:''}
                                                onChange={this.handleUserlevelChanged}>
                                        <option value="0">{I18n.t('user.level0')}</option>     
                                        <option value="1">{I18n.t('user.level1')}</option>
                                        <option value="2">{I18n.t('user.level2')}</option>                                          
                                    </FormControl>
                                </FormGroup>
                                {
                                    this.state.dialogData.level>0 
                                    &&<FormGroup>
                                        <ControlLabel><Translate value='user.Subscribled_Expired' /></ControlLabel>
                                        <FormGroup>
                                            <Datetime inputProps={{
                                                readOnly: true, 
                                                disabled: !this.state.editable, 
                                                className: !!this.state.editable ? 'date-readonly form-control' : 'form-control'
                                            }}
                                                value={this.state.dialogData.expirePackage} 
                                                timeFormat={false}
                                                onChange={this.handlePackageExpiredChanged} />
                                        </FormGroup>
                                    </FormGroup>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <ControlLabel>(<span className='require'>*</span>): <Translate value='user.Required_fields' /></ControlLabel>
                                {!this.props.permissions || this.props.permissions.actions ? this.state.editable ?
                                    <Button bsStyle='success' onClick={this.saveDialogForm}
                                        disabled={!Validation.isFormValid(this.state.valid)}><Translate value='user.Save' /></Button> 
                                        : <Button bsStyle='success' onClick={(e) =>{this.handleMenuClick("changeStatus", e)}}>
                                        <Translate value='user.Edit' /></Button>
                                    : null
                                }
                                <Button bsStyle="warning" onClick={this.closeDialogForm}><Translate value='user.Cancel' /></Button>
                            </Modal.Footer>
                        </Modal>
                }
                <div className='row-fluid clearfix table-header-controls-container'>
                    {
                        !this.props.permissions || this.props.permissions.actions ?
                        <FormGroup className='table-header-control'>
                            <ButtonToolbar className='text-center header-button-group'>
                                <Button bsSize='lg' bsStyle='success' onClick={this.handleAddButtonClick}> <Glyphicon glyph='plus' /><Translate value='user.Add' /></Button>
                            </ButtonToolbar>    
                        </FormGroup> : ''
                    }
                    <FormGroup className='table-header-control'>
                        <ControlLabel>
                            <Translate value='user.User_type' />
                        </ControlLabel>
                        <FormControl componentClass="select" placeholder="select" onChange={(e)=>{this.handleFilter('userType',e)}} value={this.state.filter.userType}>
                            <option value="">{I18n.t('user.All')}</option>
                            <option value="user">{I18n.t('user.User')}</option>
                            <option value="admin">{I18n.t('user.Admin')}</option>
                        </FormControl>
                    </FormGroup>
                    <FormGroup className='table-header-control'>
                        <ControlLabel>
                            <Translate value='user.Key_words' />
                        </ControlLabel>
                        <FormControl
                            type="text"
                            placeholder='Enter text for fillter'
                            onChange={(e)=>{this.handleFilter('keyword',e)}}
                            value = {this.state.filter.keyword}
                        />
                    </FormGroup>
                </div>
                <TableHeader
                    data={this.state.users} isHeader={true}
                    name={''}
                    handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                    handlePaginationSelect={this.handlePaginationSelect}
                />
                <table className="table table-striped table-hover">
                    <thead>
                        <tr className=''>
                            <th><Translate value='user.Email' /></th>
                            <th><Translate value='user.Name' /></th>
                            <th><Translate value='user.Gender' /></th>
                            <th><Translate value='user.User_type' /></th>
                            <th><Translate value='user.User_Level' /></th>
                            <th><Translate value='user.Actions' /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderDataItemList()}
                    </tbody>
                </table>
                <TableHeader
                    data={this.state.users} isHeader={false}
                    name={''}
                    handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                    handlePaginationSelect={this.handlePaginationSelect}
                />
            </div>
        )
    }
}

User.contextTypes = {
    notification: PropTypes.func,
}

function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        socket: state.socket,
        users: state.users,
        permissions: state.menuHandle.modulePermission
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(User);