import {callApi,callApiBase,loadUserProfile, UrlBuilder} from '../utils/apiUtils'
import { packageCreateApi,
        packageDeleteApi,
        packageGetDetailApi,
        packageGetListApi,
        packageUpdateApi} from '../constants/ApiConfigs'

export function packageGetDetail(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        packageGetDetailApi,
        config,
        null,
        null,
        null,
        true
    )
}

export function packageGetList(){
    let config = {
        method: 'get'
    }
    return callApi(
        packageGetListApi,
        config,
        null,
        null,
        null,
        true
    )
}

export function packageCreate(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        packageCreateApi,
        config,
        null,
        null,
        null,
        true
    )
}
export function packageUpdate(options){
    let config = {
        method: 'post',
        body:JSON.stringify(options)
    }
    return callApi(
        packageUpdateApi,
        config,
        null,
        null,
        null,
        true
    )
}
export function packageDelete(options){
    let config = {
        method: 'DELETE',
        body:JSON.stringify(options)
    }
    return callApi(
        packageDeleteApi,
        config,
        null,
        null,
        null,
        true
    )
}