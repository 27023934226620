import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Translate, I18n } from 'react-redux-i18n'
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
  Button,
  Radio,
  Glyphicon,
  ButtonToolbar,
  DropdownButton,
  MenuItem,
  Modal,
  ListGroup,
  ListGroupItem,
  Checkbox,
  Tab,
  Tabs,
  Alert,
  Pagination
} from 'react-bootstrap'
import * as categoryActions from '../../actions/categoryActions'
import * as loadingBarActions from './../../actions/loadingBarActions'
import { bindActionCreators } from 'redux'
import { Validator, ValidCase } from '../../components/validator'
import { Validation } from '../../utils/commonFunctions'
import TableHeader from '../../components/table/TableHeader'

class Category extends Component {
  constructor() {
    super()
    this.state = {
      categoris: null,
      activePage: 1,
      numItemsPerPage: 20,
      detailItem: null,
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      sortType: -1,
      valid: {},
      isSubmitted: false,
      editable: false
    }
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this)
    this.handlePaginationChose = this.handlePaginationChose.bind(this)
    this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(this)
    this.handleMenuClick = this.handleMenuClick.bind(this)
    this.updateCategorisList = this.updateCategorisList.bind(this)
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this)
    this.closeDialogForm = this.closeDialogForm.bind(this)
    this.saveDialogForm = this.saveDialogForm.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleOrderChange = this.handleOrderChange.bind(this)
    this.confirmDeleteUser = this.confirmDeleteUser.bind(this)
    this.ValidatorCallback = this.ValidatorCallback.bind(this)
  }
  componentDidMount() {
    this.updateCategorisList()
  }

  updateCategorisList(activePage) {
    this.props.categoryActions.categoryGetAll().then((data) => {
      if (data.ok && data.res) {
        this.setState({ categoris: data.res })
      }
    })
  }

  saveDialogForm() {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true })
    }
    if (!Validation.isFormValid(this.state.valid)) {
      return
    }
    this.props.loadingBarActions.showLoadingSpiner()
    if (this.state.dialogData._id) {
      let objectCategory = {
        name: this.state.dialogData.name,
        id: this.state.dialogData._id,
        order: this.state.dialogData.order
      }
      this.props.categoryActions.categoryUpdate(objectCategory).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner()
        if (!data.ok) {
          if (data.error) {
            this.context.notification('error', I18n.t('errors.' + data.error.errorCode))
          } else {
            this.context.notification('error', I18n.t('category.Update_category_fail'))
          }
        } else {
          this.context.notification('success', I18n.t('category.Update_category_success'))
          this.closeDialogForm()
          this.updateCategorisList(this.state.activePage)
          this.setState({ isSubmited: false })
        }
      })
    } else {
      this.props.categoryActions.categoryCreate(this.state.dialogData).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner()
        if (!data.ok) {
          if (data.error) {
            this.context.notification('error', I18n.t('errors.' + data.error.errorCode))
          } else {
            this.context.notification('error', I18n.t('category.Create_category_fail'))
          }
        } else {
          this.context.notification('success', I18n.t('category.Create_category_success'))
          this.closeDialogForm()
          this.updateCategorisList(this.state.activePage)
          this.setState({ isSubmited: false })
        }
      })
    }
  }
  handleNameChange(e) {
    this.state.dialogData.name = e.target.value
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true })
  }

  handleOrderChange(e) {
    this.state.dialogData.order = e.target.value
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true })
  }

  handleAddButtonClick() {
    let objectComany = {
      name: ''
    }
    this.setState({ showDialog: true, dialogData: objectComany, editable: true })
  }
  closeDialogForm() {
    this.setState({ showDialog: false, dialogData: null, dialogChanged: false, showConfirm: false, editable: false })
  }
  handleMenuClick(key, category) {
    switch (key) {
      case 'Edit': {
        if (category) {
          this.setState({ showDialog: true, dialogData: category, dialogChanged: false, editable: true })
        } else {
          this.context.notification('error', I18n.t('category.Load_category_fail'))
        }
        break
      }
      case 'View': {
        if (category) {
          this.setState({ showDialog: true, dialogData: category, dialogChanged: false, editable: false })
        } else {
          this.context.notification('error', I18n.t('category.Load_category_fail'))
        }
        break
      }
      case 'changeStatus': {
        this.setState({ editable: true })
        break
      }
      case 'Delete': {
        this.setState({ showConfirm: true, dialogData: category })
        break
      }
    }
  }

  handlePaginationSelect(eventKey) {
    this.updateCategorisList(eventKey)
  }

  handlePaginationChose(e) {
    this.updateCategorisList(parseInt(e.target.value))
  }

  handleNumItemsPerPageChange(e) {
    let numItemsPerPage = parseInt(e.target.value)
    let numPage = 1
    this.setState(
      {
        numItemsPerPage: numItemsPerPage
      },
      function () {
        this.updateCategorisList(numPage)
      }
    )
  }

  confirmDeleteUser() {
    this.props.categoryActions
      .categorydelete({
        id: this.state.dialogData._id
      })
      .then((data) => {
        if (data.ok) {
          this.closeDialogForm()
          this.updateCategorisList()
          this.context.notification('success', I18n.t('category.Delete_category_success'))
        } else {
          if (data.error) {
            this.context.notification('error', I18n.t('errors.' + data.error.errorCode))
          } else {
            this.context.notification('error', I18n.t('category.Delete_category_fail'))
          }
        }
      })
  }
  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid
      this.setState({ valid: this.state.valid })
    }
  }

  renderDataItemList() {
    const { handleMenuClick, changeStatus } = this
    if (this.state.categoris && this.state.categoris.list.length > 0) {
      return this.state.categoris.list.map((category) => {
        return (
          <tr key={category._id}>
            <td scope="row" style={{ width: '90%' }}>
              {
                <a
                  className="clickable"
                  onClick={() => {
                    handleMenuClick('View', category)
                  }}
                >
                  {category.name}
                </a>
              }
            </td>
            <td>{category.order}</td>
            <td style={{ width: '10%' }}>
              <DropdownButton
                title={<Translate value="category.Actions" />}
                pullRight
                id="bg-nested-dropdown"
                onSelect={(eventKey) => {
                  handleMenuClick(eventKey, category)
                }}
              >
                <MenuItem eventKey="Edit">
                  <Translate value="category.Edit" />
                </MenuItem>
                <MenuItem eventKey="Delete">
                  <Translate value="category.Delete" />
                </MenuItem>
              </DropdownButton>
            </td>
          </tr>
        )
      })
    } else {
      return (
        <tr>
          <td className="item-no-found" colSpan={2}>
            <Translate value="messages.item_no_found" />
          </td>
        </tr>
      )
    }
  }
  render() {
    return (
      <div className="content">
        <Modal show={this.state.showConfirm} backdrop={true} onHide={this.closeDialogForm}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="category.DELETE_CATEGORY" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Translate value="category.DELETE_CONFIRM" />
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="success" onClick={this.confirmDeleteUser}>
              <Translate value="category.Yes" />
            </Button>
            <Button bsStyle="warning" onClick={this.closeDialogForm}>
              <Translate value="category.Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.dialogData && (
          <Modal show={this.state.showDialog} backdrop={true} onHide={this.closeDialogForm}>
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.dialogData._id ? (
                  this.state.editable ? (
                    <Translate value="category.EDIT_CATEGORY" />
                  ) : (
                    <Translate value="category.DETAIL_CATEGORY" className="upper" />
                  )
                ) : (
                  <Translate value="category.ADD_CATEGORY" />
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormGroup validationState={!this.state.isSubmited ? null : this.state.valid.name ? null : 'error'}>
                <ControlLabel>
                  <Translate value="category.Name" />
                  <span className="require">*</span>
                </ControlLabel>
                <FormControl
                  type="text"
                  onChange={this.handleNameChange}
                  value={this.state.dialogData ? this.state.dialogData.name : ''}
                  disabled={!this.state.editable}
                  placeholder={I18n.t('categorySetting.Name')}
                />
                <Validator id="name" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmited}
                    valid={!Validation.isStringEmpty(this.state.dialogData.name)}
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                </Validator>
              </FormGroup>
              <FormGroup>
                <ControlLabel>
                  <Translate value="category.Order" />
                  <span className="require">*</span>
                </ControlLabel>
                <FormControl
                  type="number"
                  onChange={this.handleOrderChange}
                  value={this.state.dialogData ? this.state.dialogData.order : ''}
                  disabled={!this.state.editable}
                  placeholder={I18n.t('categorySetting.Order')}
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <ControlLabel>
                (<span className="require">*</span>): <Translate value="category.Required_fields" />
              </ControlLabel>
              {!this.props.permissions || this.props.permissions.actions ? (
                this.state.editable ? (
                  <Button bsStyle="success" onClick={this.saveDialogForm} disabled={!Validation.isFormValid(this.state.valid)}>
                    <Translate value="category.Save" />
                  </Button>
                ) : (
                  <Button
                    bsStyle="success"
                    onClick={(e) => {
                      this.handleMenuClick('changeStatus', e)
                    }}
                  >
                    <Translate value="carSetting.Edit" />
                  </Button>
                )
              ) : null}
              <Button bsStyle="warning" onClick={this.closeDialogForm}>
                <Translate value="category.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {!this.props.permissions || this.props.permissions.actions ? (
          <ButtonToolbar className="text-center header-button-group">
            <Button bsStyle="success" onClick={this.handleAddButtonClick}>
              {' '}
              <Glyphicon glyph="plus" />
              <Translate value="category.Add" />
            </Button>
          </ButtonToolbar>
        ) : (
          ''
        )}
        <TableHeader
          data={this.state.categoris}
          isHeader={true}
          name={''}
          handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
          handlePaginationSelect={this.handlePaginationSelect}
        />
        <table className="table table-striped table-hover">
          <thead>
            <tr className="">
              <th style={{ width: '80%' }}>
                <Translate value="category.Name" />
              </th>
              <th style={{ width: '10%' }}>
                <Translate value="category.Order" />
              </th>
              <th style={{ width: '10%' }}>
                <Translate value="category.Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{this.renderDataItemList()}</tbody>
        </table>
        <TableHeader
          data={this.state.categoris}
          isHeader={false}
          name={''}
          handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
          handlePaginationSelect={this.handlePaginationSelect}
        />
      </div>
    )
  }
}

Category.contextTypes = {
  notification: PropTypes.func
}

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    socket: state.socket,
    users: state.users,
    permissions: state.menuHandle.modulePermission
  }
}

function mapDispatchToProps(dispatch) {
  return {
    categoryActions: bindActionCreators(categoryActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Category)
