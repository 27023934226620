import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Validator, ValidCase } from '../../components/validator'
import { Validation } from '../../utils/commonFunctions'
import * as loadingBarActions from './../../actions/loadingBarActions'
import { editProfile } from '../../actions/auth'
import {
    Col,
    Row,
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    InputGroup,
    Button, Radio,
    Glyphicon,
    ButtonToolbar,
    DropdownButton,
    MenuItem,
    Modal,
    ListGroup,
    ListGroupItem,
    Checkbox,
    Tab,
    Tabs,
    Alert,
    Pagination
} from 'react-bootstrap';

class UserProfile extends Component {
    constructor() {
        super();
        this.state = {
            valid: {},
            isSubmitted: false,
            isEditting: false
        };
        this.ValidatorCallback = this.ValidatorCallback.bind(this);
        this.handleFirstnameChange = this.handleFirstnameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
    }
    componentDidMount() {
        if (this.props.auth) {
            this.setState(Object.assign({}, this.state, this.props.auth.user));
        }
    }

    componentWillReceiveProps(nextProps) {
    }

    handleFirstnameChange(e) {
        this.setState({ firstName: e.target.value });
    }
    handleLastNameChange(e) {
        this.setState({ lastName: e.target.value });
    }
    handleEmailChange(e) {
        this.setState({ email: e.target.value });
    }
    handleAddressChange(e) {
        this.setState({ address: e.target.value });
    }

    handleSaveClick() {
        if (!this.state.isEditting) {
            this.setState({ isEditting: true });
            return;
        }
        this.setState({ isSubmited: true })
        this.props.loadingBarActions.showLoadingSpiner();
        this.props.editProfile({
            userId: this.state._id,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            address: this.state.address
        }).then(data => {
            this.props.loadingBarActions.hideLoadingSpiner();
            if (data.ok) {
                this.setState({ isSubmitted: false, isEditting: false })
            } else {
                this.setState({ isSubmitted: false })
                this.context.notification('error', I18n.t('errors.' + data.error.errorCode));
            }
        });
    }

    ValidatorCallback(id, valid, messages) {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    }

    render() {
        return (
            <div className='content'>
                <div style={{ width: '30%' }}>
                    <FormGroup>
                        <ControlLabel><Translate value='userProfile.Username' /></ControlLabel>
                        <FormControl
                            type="text"
                            value={this.state.userName}
                            disabled={true}
                            placeholder={I18n.t("userProfile.Username")}
                        />
                    </FormGroup>
                    <FormGroup validationState={!this.state.isSubmited ? null : (this.state.valid.firstName === false ? 'error' : null)}>
                        <ControlLabel><Translate value='userProfile.First_name' /><span className='require'>*</span></ControlLabel>
                        <FormControl
                            type="text"
                            onChange={this.handleFirstnameChange}
                            value={this.state.firstName}
                            placeholder={I18n.t("userProfile.First_name")}
                            disabled={!this.state.isEditting}
                        />
                        <Validator id='firstName' callback={this.ValidatorCallback}>
                            <ValidCase
                                hide={!this.state.isSubmited}
                                valid={!Validation.isStringEmpty(this.state.firstName)}
                                message={I18n.t('userProfile.ERROR_INPUT_FIRSTNAME')}
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel><Translate value='userProfile.Last_name' /></ControlLabel>
                        <FormControl
                            type="text"
                            onChange={this.handleLastNameChange}
                            value={this.state.lastName}
                            placeholder={I18n.t("userProfile.Last_name")}
                            disabled={!this.state.isEditting}
                        />
                    </FormGroup>
                    <FormGroup validationState={!this.state.isSubmited ? null : (this.state.valid.email === false ? 'error' : null)}>
                        <ControlLabel><Translate value='userProfile.Email_address' /></ControlLabel>
                        <FormControl
                            type="text"
                            onChange={this.handleEmailChange}
                            value={this.state.email}
                            placeholder={I18n.t("userProfile.Email_address")}
                            disabled={!this.state.isEditting}
                        />
                        <Validator id='email' callback={this.ValidatorCallback}>
                            <ValidCase
                                hide={!this.state.isSubmited}
                                valid={Validation.isStringEmpty(this.state.email) || Validation.validateEmail(this.state.email)}
                                message={I18n.t('userProfile.ERROR_INPUT_VALID_EMAIL')}
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel><Translate value='userProfile.Address' /></ControlLabel>
                        <FormControl
                            type="text"
                            onChange={this.handleAddressChange}
                            value={this.state.address}
                            placeholder={I18n.t("userProfile.Address")}
                            disabled={!this.state.isEditting}
                        />
                    </FormGroup>
                    <ControlLabel>(<span className='require'>*</span>): <Translate value='userProfile.Required_fields' />
                    </ControlLabel>

                    <ButtonToolbar className='text-center'>
                        <Button bsStyle='success' onClick={this.handleSaveClick} type='submit'><Glyphicon glyph='floppy-save' />
                            {this.state.isEditting ? <Translate value='userProfile.Save' /> : <Translate value='userProfile.Edit_profile' />}</Button>
                        <Button bsStyle="warning" onClick={() => {
                            this.props.router.push('/change-password')
                        }}><Glyphicon glyph='pencil' /><Translate value='userProfile.Change_password' /></Button>
                    </ButtonToolbar>
                </div>
            </div>
        )
    }
}

UserProfile.contextTypes = {
    notification: PropTypes.func,
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        editProfile: (options) => { return dispatch(editProfile(options)) },
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);